import lang from "src/assets/lang.json";
import iso from "src/assets/iso.json";
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { WebsocketService } from "src/app/core/services/websocket.service";
import { Router } from "@angular/router";
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from "@angular/forms";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { ListUserCoupons } from "src/app/core/services/outbound";
import {
  coupon_share_icon_map,
  device_os_map,
  user_role_color_map,
  user_role_map,
  user_search_placeholder_map,
  user_status_color_map,
  user_status_map,
} from "src/app/core/services/constants";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { Coupon } from "src/app/core/services/interface";

@Component({
  selector: "app-coupons-selector",
  templateUrl: "./coupons-selector.component.html",
  styleUrls: ["./coupons-selector.component.scss"],
  providers: [],
})

/**
 * Contacts domain-list component
 */
export class CouponsSelectorComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() mode = "list";
  @Input() single = false;
  @Input() selectedChats = [];
  @Input() type: string;
  @Output() selectedNotifier = new EventEmitter<any>();
  @Output() unSelectedNotifier = new EventEmitter<any>();
  @Output() cancelNotifier = new EventEmitter<boolean>(false);
  // bread crumb items
  breadCrumbItems: Array<{}>;
  page: Array<any> = [];
  eop = false;
  pageSize = 25;
  currentPage = 0;
  couponList: {};
  couponListSubscription: any;
  setUserCouponSubscription: any;
  monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];
  status_map = {};
  status_color_map = {};
  user_role_map = {};
  user_role_color_map = {};
  user_search_placeholder_map = {};
  device_os_map = {};
  coupon_share_icon_map = {};
  payload = {};
  couponListForm: UntypedFormGroup;
  submitted = false;
  dupe = false;
  criterionCount = 0;
  component_ref: any;
  search_message = "";
  countries = [];
  loading = false;
  verifySubscription: any;
  my_id: any;
  status: string;
  modalRef?: BsModalRef;

  coupon: Coupon;

  selectedChatsList = {};

  constructor(
    private ws: WebsocketService,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private mdw: MiddlwareService,
    private modalService: BsModalService,
    private ref: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.countries = iso.map((a) => a.name);
    this.status_map = user_status_map;
    this.status_color_map = user_status_color_map;
    this.user_role_map = user_role_map;
    this.user_role_color_map = user_role_color_map;
    this.device_os_map = device_os_map;
    this.coupon_share_icon_map = coupon_share_icon_map;
    this.user_search_placeholder_map = user_search_placeholder_map;

    this.component_ref = this.mdw.makeRef(16);
    this.currentPage = 1;
    this.couponList = {};

    this.couponListForm = this.formBuilder.group({
      member_id: new UntypedFormControl(null),
      search_value: new UntypedFormControl(null),
      status: new UntypedFormControl(""),
      role_id: new UntypedFormControl(""),
      date: new UntypedFormControl(null),
      search_type: new UntypedFormControl("name"),
    });

    this.couponListSubscription = this.ws.listCouponsContainer$.subscribe(
      (listCouponsData) => {
        if (listCouponsData.ref === this.component_ref) {
          if (listCouponsData) {
            this.loading = false;
            this.currentPage = listCouponsData.page_number;
            if (listCouponsData.coupons.length == 0 && this.currentPage == 1) {
              this.search_message = "No results matched your search criteria";
            }

            listCouponsData.coupons.forEach((coupon: any) => {
              coupon.created_date = new Date(coupon.created_date);
              coupon.created_date =
                coupon.created_date.getDate() +
                " " +
                this.monthNames[coupon.created_date.getMonth()] +
                ", " +
                coupon.created_date.getFullYear();

              this.couponList[
                String(coupon.group_id) + String(coupon.coupon_id)
              ] = coupon;
            });

            this.page = [];
            for (let i = 0; i < this.pageSize; i++) {
              if (
                Object.keys(this.couponList)[
                  i + (this.currentPage - 1) * this.pageSize
                ]
              ) {
                this.page.push(
                  this.couponList[
                    Object.keys(this.couponList)[
                      i + (this.currentPage - 1) * this.pageSize
                    ]
                  ]
                );
              }
            }

            document
              .getElementById("pagination_back")
              .classList.remove("d-none");
            document
              .getElementById("pagination_back")
              .classList.add("enablePointer");
            document
              .getElementById("pagination_next")
              .classList.remove("d-none");
            document
              .getElementById("pagination_next")
              .classList.add("enablePointer");

            this.eop = false;
            if (listCouponsData.eop) {
              this.eop = true;
              document
                .getElementById("pagination_next")
                .classList.add("d-none");
              document
                .getElementById("pagination_next")
                .classList.remove("enablePointer");
            } else {
              document
                .getElementById("pagination_next")
                .classList.remove("d-none");
              document
                .getElementById("pagination_next")
                .classList.add("enablePointer");
            }
            if (this.currentPage == 1) {
              document
                .getElementById("pagination_back")
                .classList.add("d-none");
              document
                .getElementById("pagination_back")
                .classList.remove("enablePointer");
            } else {
              document
                .getElementById("pagination_back")
                .classList.remove("d-none");
              document
                .getElementById("pagination_back")
                .classList.add("enablePointer");
            }
            if (this.currentPage == 1 && listCouponsData.eop) {
              document
                .getElementById("showPageNumber")
                .classList.add("invisible");
            } else {
              document
                .getElementById("showPageNumber")
                .classList.remove("invisible");
            }
            this.ref.detectChanges();
          }
        }
      }
    );

    this.verifySubscription = this.ws.verifyContainer$.subscribe(
      (verifyData) => {
        if (verifyData) {
          this.my_id = this.mdw.getID();
          const date = new Date();
          const formatter = new Intl.DateTimeFormat("en-US", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          });
          const formattedDate = formatter.format(date).replace(/\//g, '-');
          // this.payload["expire"] = formattedDate;
          // this.payload["status"] = 'A';
          this.ws.processMethod(
            new ListUserCoupons(
              0,
              localStorage.getItem("chat_id"),
              this.component_ref,
              this.payload["coupon_id"],
              this.payload["name"],
              this.payload["created_date"],
              this.payload["status"],
              this.payload["expire"],
            )
          );
        }
      }
    );

    this.breadCrumbItems = [
      { label: "MENUITEMS.LOYALTYMANAGER.TEXT" },
      { label: "COUPONS.COUPONS", active: true },
    ];

    if (this.selectedChats && this.selectedChats.length > 0) {
      for (let i = 0; this.selectedChats.length > i; i++) {
        this.selectedChatsList[this.selectedChats[i].coupon_id] =
          this.selectedChats[i];
      }
    }
  }

  ngOnDestroy(): void {
    if (this.couponListSubscription) {
      this.couponListSubscription.unsubscribe();
    }
    if (this.setUserCouponSubscription) {
      this.setUserCouponSubscription.unsubscribe();
    }
  }

  ngAfterViewInit(): void {
    document.getElementById("pagination_back").classList.add("d-none");
    document
      .getElementById("pagination_back")
      .classList.remove("enablePointer");
    document.getElementById("pagination_next").classList.add("d-none");
    document
      .getElementById("pagination_next")
      .classList.remove("enablePointer");
  }

  openDetails(data: any) {
    this.router.navigate([
      "/" +
        data.account_id +
        "/" +
        data.ref_domain +
        "/" +
        data.service.product,
    ]);
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.couponListForm.controls;
  }
  get criteria(): UntypedFormArray {
    return this.couponListForm.get("criteria") as UntypedFormArray;
  }

  /**
   * Add the member field in form
   */
  addCriterion() {
    this.criteria.push(
      new UntypedFormGroup({
        criterion: new UntypedFormControl(null),
        min: new UntypedFormControl(null),
        max: new UntypedFormControl(null),
      })
    );
  }

  /**
   * Onclick delete member from form
   */
  deleteCriterion(i: number) {
    this.criteria.removeAt(i);
  }

  onSubmit() {
    this.loading = true;
    let id =
      this.f.search_type.value === "coupon_id"
        ? this.f.search_value.value
        : null;
    let name =
      this.f.search_type.value === "name" ? this.f.search_value.value : null;
    let date = this.f.date.value;
    this.search(id, name, date);
  }

  search(id: any, name: any, date: any) {
    this.currentPage = 0;
    this.couponList = {};
    this.payload = {};
    this.search_message = "";

    this.payload = {
      page_size: this.pageSize,
      page_number: 0,
      chat_id: localStorage.getItem("chat_id"),
    };

    if (id != null && id !== "") {
      this.payload["coupon_id"] = id;
    }
    if (name != null && name !== "") {
      this.payload["name"] = name;
    }
    if (date != null && date !== "") {
      this.payload["created_date"] = date;
    }

    this.ws.processMethod(
      new ListUserCoupons(
        0,
        localStorage.getItem("chat_id"),
        this.component_ref,
        this.payload["coupon_id"],
        this.payload["name"],
        this.payload["created_date"]
      )
    );
  }
  resetSearch() {
    this.currentPage = 0;
    this.couponList = {};
    this.search_message = "";

    this.payload["page_size"] = this.pageSize;
    this.payload["page_number"] = this.currentPage;
    this.payload["chat_id"] = localStorage.getItem("chat_id");

    this.ws.processMethod(
      new ListUserCoupons(
        this.currentPage,
        localStorage.getItem("chat_id"),
        this.component_ref,
        this.payload["coupon_id"],
        this.payload["name"],
        this.payload["created_date"]
      )
    );
  }

  nextPage() {
    // console.log("request");
    this.payload["page_size"] = this.pageSize;
    this.payload["page_number"] = this.currentPage;
    this.payload["chat_id"] = localStorage.getItem("chat_id");

    this.ws.processMethod(
      new ListUserCoupons(
        this.currentPage,
        localStorage.getItem("chat_id"),
        this.component_ref,
        this.payload["coupon_id"],
        this.payload["name"],
        this.payload["created_date"]
      )
    );
  }

  backPage() {
    if (this.currentPage != 1) {
      this.currentPage--;
      this.page = [];
      for (let i = 0; i < this.pageSize; i++) {
        if (
          Object.keys(this.couponList)[
            i + (this.currentPage - 1) * this.pageSize
          ]
        ) {
          this.page.push(
            this.couponList[
              Object.keys(this.couponList)[
                i + (this.currentPage - 1) * this.pageSize
              ]
            ]
          );
        }
      }
      if (this.currentPage == 1) {
        document.getElementById("pagination_back").classList.add("d-none");
        document
          .getElementById("pagination_back")
          .classList.remove("enablePointer");
      }
      if (
        this.currentPage <
        Object.keys(this.couponList).length / this.pageSize
      ) {
        document.getElementById("pagination_next").classList.remove("d-none");
        document
          .getElementById("pagination_next")
          .classList.add("enablePointer");
      }
    }
  }

  abbreviateNumber(value: any) {
    var newValue = value;
    if (value >= 1000) {
      var suffixes = ["", "K", "M", "B", "T"];
      var suffixNum = Math.floor(("" + value).length / 3);
      var shortValue: any;
      for (var precision = 2; precision >= 1; precision--) {
        shortValue = parseFloat(
          (suffixNum != 0
            ? value / Math.pow(1000, suffixNum)
            : value
          ).toPrecision(precision)
        );
        var dotLessShortValue = (shortValue + "").replace(
          /[^a-zA-Z 0-9]+/g,
          ""
        );
        if (dotLessShortValue.length <= 2) {
          break;
        }
      }
      if (shortValue % 1 != 0) shortValue = shortValue.toFixed(1);
      newValue = shortValue + suffixes[suffixNum];
    }
    return newValue;
  }

  getCountryName(alpha: string) {
    return iso.find((e) => e.alpha === alpha)?.name;
  }

  getPercent(frac: number) {
    return (frac * 100).toFixed(2).replace(/[.,]00$/, "") + "%";
  }

  getLangName(code: string) {
    return lang.find((e) => e.code === code)?.native;
  }

  checkUncheckAll(ev: any) {}

  onChanges(e: any) {
    this.loading = true;
    this.search_message = "";
    this.payload = {};
    if (e.heading === "All") {
      this.component_ref = this.mdw.makeRef(16);
      this.currentPage = 0;
      this.couponList = {};
      this.ws.processMethod(
        new ListUserCoupons(
          0,
          localStorage.getItem("chat_id"),
          this.component_ref
        )
      );
    }
    if (e.heading === "Active") {
      this.component_ref = this.mdw.makeRef(16);
      this.couponList = {};
      this.payload["status"] = "A";
      this.ws.processMethod(
        new ListUserCoupons(
          0,
          localStorage.getItem("chat_id"),
          this.component_ref,
          this.payload["coupon_id"],
          this.payload["name"],
          this.payload["created_date"],
          this.payload["status"]
        )
      );
    }
    if (e.heading === "Expired") {
      this.component_ref = this.mdw.makeRef(16);
      this.couponList = {};
      const date = new Date();
      this.payload["expire"] = date;

      this.ws.processMethod(
        new ListUserCoupons(
          0,
          localStorage.getItem("chat_id"),
          this.component_ref,
          this.payload["coupon_id"],
          this.payload["name"],
          this.payload["created_date"],
          this.payload["status"],
          this.payload["expire"]
        )
      );
    }
  }

  openModal(content: any) {
    this.modalRef = this.modalService.show(content, { class: "modal-md" });
  }

  openForm(content) {
    this.coupon = null;
    this.openModal(content);
  }

  viewEdit(coupon: Coupon, content) {
    this.coupon = { ...coupon };
    this.openModal(content);
  }

  closeForm() {
    this.modalRef.hide();
  }

  insertEvent() {
    const selected = Object.values(this.selectedChatsList);
    this.selectedNotifier.emit(selected);
  }

  cancelEvent() {
    this.cancelNotifier.emit(true);
  }

  isObjEmpty() {
    return Object.keys(this.selectedChatsList).length === 0;
  }

  isSelected(id: string) {
    return this.selectedChatsList[id] ? true : false;
  }

  editCoupon(data) {
    if (this.single) {
      if (this.selectedChatsList[data.coupon_id]) {
        delete this.selectedChatsList[data.coupon_id];
      } else {
        this.selectedChatsList = {};
        this.selectedChatsList[data.coupon_id] = data;
      }
    } else {
      if (this.selectedChatsList[data.coupon_id]) {
        delete this.selectedChatsList[data.coupon_id];
      } else {
        this.selectedChatsList[data.coupon_id] = data;
      }
    }
  }
}

<div
  class="offcanvas offcanvas-width offcanvas-end navbar-brand-box p-0 z-1"
  style="top: 70px; visibility: visible !important"
  data-bs-scroll="true"
  data-bs-backdrop="false"
  tabindex="-1"
  id="offcanvasScrolling"
  aria-labelledby="offcanvasScrollingLabel"
>
  <div class="offcanvas-header ps-0 offcanvas-divider" >
    <div id="sidebar-menu" class="p-0 offcanvas-header">
      <ul class="metismenu list-unstyled">
        <li draggable="false">
          <a class="side-nav-link-ref">
            <span class="fw-bold font-size-16">App Components</span>
          </a>
        </li>
      </ul>
    </div>
    <div
      class="text-reset enablePointer"
      data-bs-dismiss="offcanvas"
      aria-label="Close"
      (click)="hideOffcanvas()"
    >
      <i class="mdi mdi-close font-size-22 icon-close"></i>
    </div>
  </div>
  <div class="offcanvas-body offcanvas-menu simplebar-content-wrapper p-0 m-0">
    <ng-container *ngIf="offcanvas_data.length == 1">
      <ng-container *ngFor="let tab of offcanvas_data">
        <div id="sidebar-menu" class="offcanvas-menu py-1 h-100">
          <!-- Left Menu Start -->
          <ul class="metismenu list-unstyled offcanvas-list-unstyled pb-1 pe-3">
            <!-- {{menuItems}} -->
            <ng-container *ngFor="let cat of tab?.data">
              <li
                *ngIf="cat.show"
                class="rounded-3 m-2 offcanvas-list"
                draggable="false"
              >
                <a class="is-parent offcanvas-parent px-3 py-2 d-flex align-items-center justify-content-between rounded-3 enablePointer" draggable="true" (dragstart)="drag($event, cat.item)" (click)="onClick(cat.item)">
                  <div class="d-flex align-items-center">
                    <i class="offcanvas-icon rounded-3 py-2 px-2 font-size-26 material-icons" [ngStyle]="{'color': cat?.icon_color}">{{builderService.getIcon(cat.item.icon).id}}</i>
                    <span class="font-size-14 ms-3">{{ cat?.item?.name | translate }}</span>
                  </div>
                </a>
              </li>
              <ng-container *ngFor="let item of cat?.list; let i = index">
                <li
                  *ngIf="!item?.options"
                  class="rounded-3 m-2"
                  draggable="false"
                >
                  <a class="side-nav-link-ref" style="text-align: left">
                    <div class="row m-0 d-flex align-items-center">
                      <i
                        class="{{ item.icon }} pb-0 pt-0 col-2"
                        *ngIf="item.icon"
                      ></i>
                      <span class="col-10 p-0" *ngIf="item.name">{{ item.name }}</span>
                      <img
                        *ngIf="item.image"
                        class="img-fluid p-0"
                        [src]="item.image"
                      />
                    </div>
                  </a>
                </li>
                <li
                  *ngIf="item?.options"
                  class="rounded-3 m-2 offcanvas-list"
                  draggable="false"
                  [id]="item?.id"
                >
                  <a class="is-parent offcanvas-parent px-3 py-2 d-flex align-items-center justify-content-between rounded-3 enablePointer" (click)="show(item?.id)">
                    <div class="d-flex align-items-center">
                      <i class="offcanvas-icon rounded-3 py-2 px-2 font-size-26 material-icons" [ngStyle]="{'color': item?.icon_color}">{{item?.icon}}</i>
                      <span class="font-size-14 ms-3">{{ item.name | translate }}</span>
                    </div>
                    <div class="dropdown-icon">
                      <i class="font-size-22 mdi mdi-chevron-down" style="min-width: 0;"></i>
                    </div>
                  </a>
                  <ul class="sub-menu offcanvas-submenu mm-collapse pt-2 pb-1 px-3 rounded-bottom-3" aria-expanded="false" draggable="false">
                    <li *ngFor="let subSubitem of item?.options" draggable="false">
                      <a class="side-nav-link-ref offcanvas-card-container p-0 mb-1 rounded-3 enablePointer" style="text-align: left" draggable="true" (dragstart)="drag($event, subSubitem)" (click)="onClick(subSubitem)">
                        <div class="d-flex align-items-center rounded-3">
                          <i *ngIf="subSubitem.icon" class="material-icons mx-2 py-2 font-size-24" style="min-width: 0;">{{builderService.getIcon(subSubitem.icon).id}}</i>
                          <div class="vr offcanvas-submenu py-4" style="width: 2px"></div>
                          <span *ngIf="subSubitem.name" class="ms-3 font-size-14">{{ subSubitem.name }}</span>
                        </div>
                      </a>
                    </li>
                  </ul>
                </li>
              </ng-container>
            </ng-container>
          </ul>
        </div>
      </ng-container>
    </ng-container>
    <tabset
      [justified]="true"
      class="nav-tabs nav-tabs-custom nav-justified tabPrimaryFilled"
      *ngIf="offcanvas_data.length > 1"
    >
      <ng-container *ngFor="let tab of offcanvas_data">
        <tab [heading]="tab?.tab">
          <div id="sidebar-menu" class="offcanvas-menu">
            <!-- Left Menu Start -->
            <ul class="metismenu list-unstyled">
              <!-- {{menuItems}} -->
              <ng-container *ngFor="let cat of tab?.data">
                <li
                  *ngIf="cat.show"
                  draggable="false"
                  style="background-color: #3f4454 !important"
                >
                  <a class="side-nav-link-ref">
                    <span>{{ cat.cat }}</span>
                  </a>
                </li>
                <div *ngFor="let item of cat?.list">
                  <li
                    *ngIf="!item?.options"
                    style="cursor: pointer"
                    draggable="true"
                    (dragstart)="drag($event, item)"
                    (click)="onClick($event, item)"
                  >
                    <a class="side-nav-link-ref" style="text-align: left">
                      <div class="row">
                        <i class="{{ item.icon }} col-2" *ngIf="item.icon"></i>
                        <span class="col-10 p-0">{{ item.name }}</span>
                      </div>
                    </a>
                  </li>
                  <li
                    *ngIf="item?.options"
                    class="offcanvas-divider"
                    style="cursor: pointer;"
                    draggable="false"
                    [id]="item?.id"
                  >
                    <a
                      href="javascript:void(0);"
                      class="is-parent"
                      [ngClass]="{
                        'has-arrow': item.options
                      }"
                      style="text-align: left"
                      (click)="show(item?.id)"
                    >
                      <i class="bx {{ item.icon }}" *ngIf="item.icon"></i>
                      <span> {{ item.name | translate }}</span>
                      <!-- <span
                      class="badge rounded-pill float-end bg-{{
                        item?.badge?.variant
                      }}"
                      *ngIf="item.name"
                      >{{ item.name | translate }}</span
                    > -->
                    </a>
                    <ul class="sub-menu mm-collapse" aria-expanded="false">
                      <li
                        *ngFor="let subSubitem of item?.options"
                        draggable="true"
                        (dragstart)="drag($event, subSubitem)"
                        (click)="onClick($event, subSubitem)"
                        style="cursor: move"
                      >
                        <a class="side-nav-link-ref" style="text-align: left">
                          {{ subSubitem.name | translate }}
                        </a>
                      </li>
                    </ul>
                  </li>
                </div>
              </ng-container>
            </ul>
          </div>
        </tab>
      </ng-container>
    </tabset>
  </div>
</div>

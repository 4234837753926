import { MenuItem } from "../sidebar/menu.model";

export enum MENUIDS {
  APPBUILDER = 1,
  APPBUILDERBRANDING,
  APPBUILDERNAVIGATION,
  APPBUILDERSIDE,
  APPBUILDERSETTINGS,
  APPBUILDERBUILD,
  DASHBOARD,
  MESSAGES,
  CHANNELS,
  CHATMANGER,
  CHAT,
  CHATSCHEDULE,
  CHATGROUPS,
  POLLS,
  CONTENTMANGER,
  PAGES,
  ARTICLE,
  GROUPS,
  MOBILECOMMERCE,
  STORES,
  COLLECTIONS,
  PRODUCTS,
  ORDERMANAGEMENT,
  ORDERS,
  BOOKINGANDEVENTS,
  MARKET,
  BOOKINGS,
  CATEGORIES,
  EVENTS,
  CALENDARS,
  USERENGAGEMENT,
  COUPONS,
  CAMPAIGNS,
  USERMANAGEMENT,
  USERS,
  USERSTAGS,
  INTEGRATIONANDAUTOMATION,
  // CHATBOT,
  API,
  EXTENSIONS,
  INSTALLED,
  ADMIN,
  ADMINLIST,
  GENERALSETTINGS,
  DEFAULTSETTINGS,
  SIGNUPSETTINGS,
  SETTINGSLIST,
  SIGNUPFILTER,
  WHITELISTANDBLACKLIST,
  TAXSETTING,
  COMMERCESETTINGS,
  SHIPPINGSETTINGS,
  CHATSETTINGS
}
export const MENU: MenuItem[] = [
  {
    isTitle: true,
    label: "App Builder"
  },
  {
    id: MENUIDS.APPBUILDER,
    label: "App",
    icon: "smartphone",
    icon_color: "#4ecb69",
    link: "/appbuilder",
    subItems: [
      {
        id: MENUIDS.APPBUILDERBRANDING,
        label: "Branding",
        link: "/appbuilder/branding",
        parentId: MENUIDS.APPBUILDER,
      },
      {
        id: MENUIDS.APPBUILDERNAVIGATION,
        label: "Navigation",
        link: "/appbuilder/navigation",
        parentId: MENUIDS.APPBUILDER,
      },
      {
        id: MENUIDS.APPBUILDERSIDE,
        label: "Side Menu",
        link: "/appbuilder/side",
        parentId: MENUIDS.APPBUILDER,
      },
      {
        id: MENUIDS.APPBUILDERSETTINGS,
        label: "Build Settings",
        link: "/appbuilder/settings",
        parentId: MENUIDS.APPBUILDER,
      },
      {
        id: MENUIDS.APPBUILDERBUILD,
        label: "Build",
        link: "/appbuilder/build",
        parentId: MENUIDS.APPBUILDER,
      }
    ],
  },
  // {
  //   id: MENUIDS.DASHBOARD,
  //   label: "MENUITEMS.DASHBOARD.TEXT",
  //   icon: 'bx bx-home-circle',
  //   link: "/appbuilder",
  // },
  {
    isTitle: true,
    label: "Messaging Center"
  },
  {
    id: MENUIDS.CHAT,
    label: "Chat",
    icon: "comment",
    icon_color: "#349fff",
    subItems: [
      {
        id: MENUIDS.MESSAGES,
        label: "Messages",
        link: "/message",
        parentId: MENUIDS.CHAT,
      },
      {
        id: MENUIDS.CHATSCHEDULE,
        label: "Schedule",
        link: "/schedule",
        parentId: MENUIDS.CHAT,
      },
    ]
  },
  {
    id: MENUIDS.CHATGROUPS,
    label: "Chat Groups",
    icon: 'groups',
    icon_color: "#fd9452",
    link: "/groups",
  },
  {
    id: MENUIDS.CALENDARS,
    label: "Shared Calendar",
    icon: 'calendar_month',
    icon_color: "#9774ff",
    link: "/calendars",
  },
  {
    isTitle: true,
    label: "Business Channels"
  },
  {
    id: MENUIDS.CHANNELS,
    label: "Channels",
    icon: 'rss_feed',
    icon_color: "#b6cd70",
    link: "/channels",
  },
  {
    id: MENUIDS.MOBILECOMMERCE,
    label: "Mobile Commerce",
    icon: "shopping_cart",
    icon_color: "#fd8888",
    subItems: [
      {
        id: MENUIDS.STORES,
        label: "Stores",
        link: "/stores",
        parentId: MENUIDS.MOBILECOMMERCE,
      },
      {
        id: MENUIDS.COLLECTIONS,
        label: "MENUITEMS.COMMERCE.LIST.COLLECTIONS",
        link: "/collections",
        parentId: MENUIDS.MOBILECOMMERCE,
      },
      {
        id: MENUIDS.PRODUCTS,
        label: "MENUITEMS.COMMERCE.LIST.PRODUCTS",
        link: "/products",
        parentId: MENUIDS.MOBILECOMMERCE,
      },
    ],
  },
  {
    id: MENUIDS.BOOKINGANDEVENTS,
    label: "MENUITEMS.BOOKINGANDEVENTS.TEXT",
    icon: "confirmation_number",
    icon_color: "#5671ff",
    subItems: [
      {
        id: MENUIDS.MARKET,
        label: "Marketplace",
        link: "/marketplace",
        parentId: MENUIDS.BOOKINGANDEVENTS,
      },
      {
        id: MENUIDS.CATEGORIES,
        label: "Categories",
        link: "/categories",
        parentId: MENUIDS.BOOKINGANDEVENTS,
      },
      {
        id: MENUIDS.BOOKINGS,
        label: "Bookings",
        link: "/bookings",
        parentId: MENUIDS.BOOKINGANDEVENTS,
      },
      {
        id: MENUIDS.EVENTS,
        label: "Events",
        link: "/events",
        parentId: MENUIDS.BOOKINGANDEVENTS,
      }
    ],
  },
  {
    id: MENUIDS.ORDERMANAGEMENT,
    label: "Order Management",
    icon: "paid",
    icon_color: "#ffd96a",
    subItems: [
      {
        id: MENUIDS.ORDERS,
        label: "MENUITEMS.COMMERCE.LIST.ORDERS",
        link: "/paymentOrders",
        parentId: MENUIDS.MOBILECOMMERCE,
      },
    ],
  },
  {
    isTitle: true,
    label: "Users"
  },
  {
    id: MENUIDS.USERS,
    label: "User Management",
    icon: 'manage_accounts',
    icon_color: "#3ce7ea",
    link: "/users",
  },
  {
    id: MENUIDS.USERENGAGEMENT,
    label: "Engagements",
    icon: "thumb_up",
    icon_color: "#cbf078",
    subItems: [
      {
        id: MENUIDS.POLLS,
        label: "MENUITEMS.CHATMANGER.LIST.POLLS",
        link: "/polls",
        parentId: MENUIDS.USERENGAGEMENT,
      },
      {
        id: MENUIDS.COUPONS,
        label: "MENUITEMS.USERENGAGEMENT.LIST.COUPONS",
        link: "/coupons",
        parentId: MENUIDS.USERENGAGEMENT,
      },
      // {
      //   id: MENUIDS.CAMPAIGNS,
      //   label: "MENUITEMS.USERENGAGEMENT.LIST.CAMPAIGNS",
      //   link: "/campaigns",
      //   parentId: MENUIDS.USERENGAGEMENT,
      // },
    ],
  },
  {
    isTitle: true,
    label: "Integrations"
  },
  // {
  //   id: MENUIDS.INTEGRATIONANDAUTOMATION,
  //   label: "MENUITEMS.INTEGRATIONANDAUTOMATION.TEXT",
  //   icon: "bx bxs-user-detail",
  //   subItems: [
      // {
      //   id: MENUIDS.CHATBOT,
      //   label: "MENUITEMS.INTEGRATIONANDAUTOMATION.LIST.CHATBOT",
      //   link: "/bots",
      //   parentId: MENUIDS.INTEGRATIONANDAUTOMATION,
      // },
  //     {
  //       id: MENUIDS.API,
  //       label: "MENUITEMS.INTEGRATIONANDAUTOMATION.LIST.API",
  //       link: "/api",
  //       parentId: MENUIDS.INTEGRATIONANDAUTOMATION,
  //     },
  //   ],
  // },
  {
    id: MENUIDS.EXTENSIONS,
    label: "MENUITEMS.EXTENSIONS.TEXT",
    icon: "extension",
    icon_color: "#1abb9c",
    subItems: [
      {
        id: MENUIDS.INSTALLED,
        label: "MENUITEMS.EXTENSIONS.LIST.INSTALLED",
        link: "/installed",
        parentId: MENUIDS.EXTENSIONS,
      }
    ],
  },
  {
    id: MENUIDS.API,
    label: "MENUITEMS.INTEGRATIONANDAUTOMATION.LIST.API",
    icon: 'integration_instructions',
    icon_color: "#e78775",
    link: "/api",
  },
  // {
  //   id: MENUIDS.CONTENTMANGER,
  //   label: "MENUITEMS.CONTENTMANGER.TEXT",
  //   icon: "mdi mdi-view-module",
  //   subItems: [
  //     {
  //       id: MENUIDS.PAGES,
  //       label: "MENUITEMS.CONTENTMANGER.LIST.PAGES",
  //       link: "/pages",
  //       parentId: MENUIDS.CONTENTMANGER,
  //     },
  //     {
  //       id: MENUIDS.ARTICLE,
  //       label: "MENUITEMS.CONTENTMANGER.LIST.ARTICLE",
  //       link: "/article",
  //       parentId: MENUIDS.CONTENTMANGER,
  //     },
  //   ],
  // },
  {
    isTitle: true,
    label: "Settings"
  },
  {
    id: MENUIDS.ADMIN,
    label: "MENUITEMS.ADMIN.TEXT",
    icon: "bx bx-lock-alt",
    subItems: [
      // {
      //   id: 11,
      //   label: "MENUITEMS.ADMIN.LIST.API",
      //   link: "/admin/api",
      //   parentId: 10,
      // },
      {
        id: MENUIDS.ADMINLIST,
        label: "MENUITEMS.ADMIN.LIST.TRANSFER",
        link: "/admin/transfer",
        parentId: MENUIDS.ADMIN,
      },
    ],
  },
  {
    id: MENUIDS.GENERALSETTINGS,
    label: "General Settings",
    icon: "settings",
    icon_color: "#2b86f3",
    subItems: [
      // {
      //   id: MENUIDS.SETTINGSLIST,
      //   label: "MENUITEMS.SETTINGS.LIST.CUSTOMFIELDS",
      //   link: "/custom-fields",
      //   parentId: MENUIDS.SETTINGS,
      // },
      {
        id: MENUIDS.DEFAULTSETTINGS,
        label: "Default Settings",
        link: "/commerce-settings",
        parentId: MENUIDS.GENERALSETTINGS,
      },
      {
        id: MENUIDS.TAXSETTING,
        label: "Tax Settings",
        link: "/tax-settings",
        parentId: MENUIDS.GENERALSETTINGS,
      },
      {
        id: MENUIDS.SHIPPINGSETTINGS,
        label: "Shipping Rates",
        link: "/shipping-settings",
        parentId: MENUIDS.GENERALSETTINGS,
      },
    ]
  },
  {
    id: MENUIDS.CHATSETTINGS,
    label: "Chat Settings",
    icon: "sell",
    icon_color: "#f7a01a",
    subItems: [
      {
        id: MENUIDS.USERSTAGS,
        label: "App User Tags",
        link: "/tags",
        parentId: MENUIDS.CHATSETTINGS,
      }
    ],
  },
  {
    id: MENUIDS.SIGNUPSETTINGS,
    label: "Signup Settings",
    icon: "login",
    icon_color: "#eb8cd2",
    subItems: [
      {
        id: MENUIDS.SIGNUPFILTER,
        label: "MENUITEMS.SETTINGS.LIST.SIGNUPFILTER",
        link: "/signup-filter",
        parentId: MENUIDS.SIGNUPSETTINGS,
      },
      {
        id: MENUIDS.WHITELISTANDBLACKLIST,
        label: "MENUITEMS.SETTINGS.LIST.WHITELISTANDBLACKLIST",
        link: "/whitelist-and-blacklist",
        parentId: MENUIDS.SIGNUPSETTINGS,
      },
    ],
  },
];

import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";

@Component({
  selector: "app-chips",
  templateUrl: "./chips.component.html",
  styleUrls: ["./chips.component.scss"],
})
export class ChipsComponent implements OnInit {
  @Input() values = [];
  @Input() placeholderText = "Enter keywords";

  @Output() emitValues = new EventEmitter<string[]>();
  constructor() {}

  ngOnInit(): void {}

  addKeyword(event) {
    event.stopPropagation();
    event.preventDefault();
    if (event.target.value) {
      this.values.push(event.target.value);
      event.target.value = "";
      this.emitValues.emit(this.values);
    }
  }

  removeKeyword(event, index) {
    event.stopPropagation();
    event.preventDefault();
    this.values.splice(index, 1);
    this.emitValues.emit(this.values);
  }
}

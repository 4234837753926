import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { c1, offcanvasColors } from "src/app/core/services/constants";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import { actionButton, Menu, MenuButton, Tab } from "src/app/core/services/interface";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { BuilderService } from "src/app/sharedservices/builder.service";

@Component({
  selector: "app-onClick-next-menu",
  templateUrl: "./onClick-next-menu.component.html",
  styleUrls: ["./onClick-next-menu.component.scss"],
})
export class OnClickNextMenuComponent implements OnInit, OnDestroy {
  constructor(
    private formBuilder: UntypedFormBuilder,
    private mdw: MiddlwareService,
    public indexDBService: IndexDBService,
    private modalService: BsModalService,
    private builderService: BuilderService,
    private router: Router
  ) {}

  labelform: UntypedFormGroup; // bootstrap validation form

  @Input() button_id: string;
  @Input() config: any;
  button: MenuButton;
  mode: string;
  enableStatic: boolean;

  // default values
  offCanvasSubscription: any;

  offcanvasColors = offcanvasColors;
  newTab = false;
  button_text: string;
  row_id: string;
  labelColor: string;
  black_color: string = "#000000";
  enableCustomColor: boolean = false;

  button_label_color: string;
  button_label_style: any;
  button_button_divider: number;
  regURL =
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
  data: any;
  currentMenuSubscription: any;
  sortedButtonList: any[];
  buttonList: any;
  off_canvas_key: string = "button";
  component_id: string;
  field_id: string;
  modalRef?: BsModalRef;
  icon: any;
  trailing_icon: any;
  iconType: number;
  fontSize = "md";
  textAlign = "left";
  isAndroid: boolean;
  light: boolean;
  text_id: any;
  colors: any;
  labelDarkColor: any;
  button_color_item: any = { schemes: { light: {}, dark: {} } };
  button_color_light: any = {};
  button_color_dark: any = {};
  sync = true;
  os_name: string;
  nextTab : Tab;
  menu:Menu;
  isTab: boolean;
  menuId: string;
  className: string[];
  imageUrl: string;

  ngOnInit() {
    this.field_id = "label";
    this.labelform = this.formBuilder.group({
      url: new UntypedFormControl("", [Validators.pattern(this.regURL)]),
    });

    this.sortedButtonList = [];
    this.buttonList = {};

    this.offCanvasSubscription = this.mdw.offCanvasContainer$.subscribe(
      (data) => {
        if (
          data &&
          data.off_canvas_key === "button" &&
          data.button &&
          data.button.button_id == this.button_id
        ) {
          this.buttonIntialize(data);
          this.buttonFunction();
        }
      }
    );



    this.currentMenuSubscription = this.mdw.currentMenuContainer$.subscribe(
      (data) => {
        if (
          data &&
          data.off_canvas_key === "button" &&
          data.currentButton &&
          this.button_id &&
          data.currentButton.button_id == this.button_id &&
          data["component_id"] != this.component_id
        ) {
          this.data = data;
          this.sortedButtonList = this.data["sortedButtonList"];
          this.buttonList = this.data["buttonList"];
          this.button = data["buttonList"][this.button_id];
          this.icon;
          this.buttonFunction();
        }
      }
    );
  }

  buttonIntialize(data) {
    this.data = data.data;

    if (data.button.button_id == this.button_id) {
      this.button = { ...data.button };
      this.component_id = this.button.button_id + this.field_id;
    }
    this.sortedButtonList = data.data["sortedButtonList"];
    this.buttonList = this.data["buttonList"];
    this.mode = localStorage.getItem("mode");
  }

  buttonFunction() {
    if (this.button) {
      this.text_id = this.config.id.split(/_(.*)/s)[1];
      this.isAndroid =localStorage.getItem("mode")[0] === c1.ANDRIOD ? true : false;
      this.os_name = this.sync ? null : this.isAndroid ? "Android" : "iOS";
      this.colors = this.config.scheme_color;
      this.className = this.config.category ? this.config.category : [];
      this.labelform = this.formBuilder.group({
        url: new UntypedFormControl(this.button.button_url, [
          Validators.pattern(this.regURL),
        ]),
      });

      if (this.button.next &&this.button.next[0] && this.button.next[0].type=='tab') {
        const tab = this.indexDBService.getItem("tab", this.button.next[0].id);
        tab.onsuccess = (event) => {
          if (tab.result) {
            this.nextTab = { ...tab.result };
            this.isTab = true;
          }
        };
      } else if (this.button.next &&this.button.next[0] && this.button.next[0].type=='menu'){
        const menu = this.indexDBService.getItem("menu", this.button.next[0].id );
        menu.onsuccess = (event) => {
          if (menu.result){
            this.isTab = false;
            // console.log("menu from INT", menu.result);

            this.menuId = menu.result.menu_id;
            const tab = this.indexDBService.getItem("tab", menu.result.menu_group);
            tab.onsuccess = (event) => {
              if (tab.result) {

                this.nextTab = { ...tab.result };

                // console.log("tab from INT", tab.result);

              }

            };
          }
        }
      }else if (this.button.next &&this.button.next[0] && this.button.next[0].type=='collection'){
        this.imageUrl=this.button.next[0].image;
      }
    }
  }
  buttonLabel() {
    if (this.labelform.valid) {
      this.button.button_url = this.f.url.value;
      this.updateButtonData();
    } else {
      return;
    }
  }

  get f() {
    if (this.labelform && this.labelform.controls) {
      return this.labelform.controls;
    }
  }

  updateButtonData() {
    //*must be there in all buttons
    this.buttonList[this.button_id] = this.button;
    let index = this.sortedButtonList[this.button.row_id].findIndex(
      (e) => e.button_id == this.button_id
    );
    this.sortedButtonList[this.button.row_id][index] = { ...this.button };
    this.data["sortedButtonList"] = this.sortedButtonList;
    this.data["buttonList"] = this.buttonList;
    this.data["off_canvas_key"] = this.off_canvas_key;
    // this.data["currentButton"] = this.button;
    this.data["nextTab"]= this.nextTab;

    this.mdw._currentMenuContainer.next(this.data);

   this.mdw.changeTab(
        { button: this.button, tab: { module: "menu" }},
        this.mode,
        true,
        true
      );
    }
  openSelector(exlargeModal: any) {
    this.modalRef = this.modalService.show(exlargeModal, { class: "modal-xl" });
  }

  nextMenu(button) {
    let allPath: any[];
    allPath = window.location.pathname.split("/");
    this.router.navigate([
      "appbuilder/" +
        allPath[2] +
        "/" +
        allPath[3] +
        "/" +
        allPath[4] +
        "/" +
        button.button_id,
    ]);
    this.mdw._currentMenuContainer.next({ fromButton: button });
  }
  onSelectScreen(value) {
    this.modalRef.hide();
    // console.log("from switch", value);
    switch (value.type) {

      case "menu":
        let menu: actionButton
        menu = {type:'menu', id:value.data.menu_id }
        this.button.next&& this.button.next.length>0? this.button.next[0] =menu : this.button.next=[menu];
        this.menu = value.data;
        this.menuId= this.menu.menu_id;
        this.isTab = false;
        // console.log("thie.menuId", this.menuId);
        break;
      case "tab":
        let tab: actionButton
        tab = {type:'tab', id:value.data.id }
        this.button.next&&  this.button.next.length>0? this.button.next[0] =tab : this.button.next=[tab];
        this.nextTab = value.data
        this.isTab = true;
        break;
    }
    this.updateButtonData();
  }
  selectedCollections(event) {
    let tab: actionButton
    tab = {type:'collection', id:event[0].id, image:  event[0].image[0].url}
    this.button.next&&  this.button.next.length>0? this.button.next[0] =tab : this.button.next=[tab];
    this.imageUrl=event[0].image[0].url;
    // this.nextTab = value.data
    this.isTab = true;
    this.modalRef.hide();
    this.updateButtonData();
  }
  openscreenselector(selectorScreen) {
    this.modalRef = this.modalService.show(selectorScreen, {
      class: "modal-xl",
    });
  }

  ngOnDestroy(): void {
    if (this.offCanvasSubscription) {
      this.offCanvasSubscription.unsubscribe();
    }
    if (this.currentMenuSubscription) {
      this.currentMenuSubscription.unsubscribe();
    }
  }
}

<div
  *ngIf="loading"
  id="preloader"
  class="bg-transparent d-flex justify-content-center align-items-center"
>
  <div class="spinner-chase">
    <div class="chase-dot"></div>
    <div class="chase-dot"></div>
    <div class="chase-dot"></div>
    <div class="chase-dot"></div>
    <div class="chase-dot"></div>
    <div class="chase-dot"></div>
  </div>
</div>
<div class="container-fluid">
  <div class="row">
    <div class="col-lg-12">
      <div class="card m-0 shadow-none">
        <div class="card-body">
          <tabset class="nav-tabs nav-tabs-custom">
            <tab
              heading="All"
              class="mt-4"
              (selectTab)="onChanges($event)"
            >
              <ng-template [ngTemplateOutlet]="TaskContent"></ng-template>
            </tab>
            <tab
              heading="{{ 'GENERALS.ACTIVE' | translate }}"
              class="mt-4"
              (selectTab)="onChanges($event)"
            >
              <ng-template [ngTemplateOutlet]="TaskContent"></ng-template>
            </tab>
            <tab
              heading="{{ 'COUPONS.EXPIRED' | translate }}"
              class="mt-4"
              (selectTab)="onChanges($event)"
            >
              <ng-template [ngTemplateOutlet]="TaskContent"></ng-template>
            </tab>
          </tabset>

          <ng-template #TaskContent>
              <form
                [formGroup]="couponListForm"
                (ngSubmit)="onSubmit()"
                class="mb-3"
              >
                <div class="row g-3">
                  <div class="col-xl-4 col-lg-8">
                    <div class="input-group">
                      <select
                        class="form-select"
                        style="max-width: 90px"
                        formControlName="search_type"
                      >
                        <option value="name" selected>
                          {{ "GENERALS.NAME" | translate }}
                        </option>
                        <option value="coupon_id">
                          {{ "COUPONS.COUPON" | translate }}
                        </option>
                      </select>
                      <input
                        type="text"
                        class="form-control"
                        [placeholder]="
                          user_search_placeholder_map[
                            couponListForm.get('search_type').value
                          ] | translate
                        "
                        formControlName="search_value"
                      />
                    </div>
                  </div>
                  <div class="col-xl-2 col-lg-4">
                    <div class="mb-3">
                      <input
                        class="form-control"
                        [placeholder]="'GENERALS.SELECTDATE' | translate"
                        name="dp"
                        bsDatepicker
                        [bsConfig]="{showWeekNumbers: false}"
                        autocomplete="off"
                        formControlName="date"
                      />
                    </div>
                  </div>
                  <div class="col-xl-2 offset-xl-4 col-lg-4 offset-lg-8">
                    <button type="submit" class="btn btn-soft-secondary w-100">
                      <i class="bx bx-search-alt align-middle"></i>
                      {{ "GENERALS.SEARCH" | translate }}
                    </button>
                  </div>
                </div>
              </form>
              <ngx-simplebar style="height: 55vh" id="scroll"  #scrollRef>
                <div class="table-responsive">
                  <table
                    class="table table-striped align-middle table-nowrap table-hover"
                  >
                    <thead class="table-light">
                      <tr>
                        <th style="width: 20px">
                          <div class="form-check font-size-16 align-middle">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              id="transactionCheck01"
                              (change)="checkUncheckAll($event)"
                            />
                            <label
                              class="form-check-label"
                              for="transactionCheck01"
                            ></label>
                          </div>
                        </th>
                        <th scope="col">{{ "GENERALS.NAME" | translate }}</th>
                        <th scope="col" class="text-center">
                          {{ "COUPONS.COUPONID" | translate }}
                        </th>
                        <th scope="col">
                          {{ "GENERALS.CREATEDDATE" | translate }}
                        </th>

                        <th scope="col" class="text-center">
                          {{ "COUPONS.SHARE" | translate }}
                        </th>
                        <th scope="col" class="text-center">
                          {{ "COUPONS.VALUE" | translate }}
                        </th>
                        <th scope="col" class="text-center">
                          {{ "GENERALS.STATUS" | translate }}
                        </th>
                        <th scope="col" class="text-center">
                          {{ "COUPONS.EXPIRY" | translate }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let data of page; let index = index">
                        <td>
                          <div class="form-check font-size-16">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              id="transactionCheck{{ index }}"
                              [checked]="isSelected(data.coupon_id)"
                              (change)="editCoupon(data)"
                            />
                            <label
                              class="form-check-label"
                              for="transactionCheck{{ index }}"
                            ></label>
                          </div>
                        </td>
                        <td>
                          <p class="mb-0 fw-bold">{{ data.name }}</p>
                        </td>
                        <td class="text-center">
                          <p class="mb-0 font-size-14 fw-bold">
                            {{ data.coupon_id }}
                          </p>
                        </td>
                        <td>
                          <p class="mb-0 font-size-14 fw-bold">
                            {{ data.created_date }}
                          </p>
                        </td>

                        <td class="text-center">
                          <p class="mb-0 font-size-14 fw-bold">
                            <i
                              class="font-size-22"
                              ngClass="{{ coupon_share_icon_map[data.share] }}"
                            ></i>
                          </p>
                        </td>
                        <td class="text-center">
                          <p class="mb-0 fw-bold">${{ data.value }}</p>
                        </td>
                        <td class="text-center">
                          <p
                            class="badge font-size-10 mb-0"
                            ngClass="{{ status_color_map[data.status] }}"
                          >
                            {{ status_map[data.status] | translate }}
                          </p>
                        </td>
                        <td class="text-center">
                          <p class="mb-0 font-size-14 fw-bold">
                            {{ data.expire }}
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="row m-0" *ngIf="search_message">
                  <div class="col text-center mt-4 mb-0">
                    <h6>
                      {{ search_message }}
                    </h6>
                  </div>
                </div>
              </ngx-simplebar>
          </ng-template>
          <div class="row">
            <div class="col-5">
              <ul class="ps-0 mt-4">
                <button
                  class="btn btn-outline-secondary d-flex align-items-center pt-0 pb-0 ps-1 p-2"
                  id="pagination_back"
                  (click)="backPage()"
                >
                  <i class="mdi mdi-chevron-left font-size-18"></i>
                  <p class="mb-0">{{ "GENERALS.PREV" | translate }}</p>
                </button>
              </ul>
            </div>
            <div class="col-2 d-flex justify-content-center align-items-center">
              <p class="avatar-xs invisible mb-0" id="showPageNumber">
                <span class="avatar-title rounded-circle bg-primary">
                  {{ currentPage }}
                </span>
              </p>
            </div>
            <div class="col-5">
              <ul class="d-flex justify-content-end ps-0 mt-4">
                <button
                  class="btn btn-outline-secondary d-flex align-items-center pt-0 pb-0 ps-2 pe-1"
                  id="pagination_next"
                  (click)="nextPage()"
                >
                  <p class="mb-0">{{ "GENERALS.NEXT" | translate }}</p>
                  <i class="mdi mdi-chevron-right font-size-18"></i>
                </button>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #content role="document" let-modal>
  <app-coupons-form
    [coupon]="coupon"
    [component_ref]="component_ref"
    (closeNotify)="closeForm()"
  ></app-coupons-form>
</ng-template>

<div class="modal-footer" *ngIf="mode === 'selector'">
  <button class="btn btn-danger me-2" (click)="cancelEvent()">Cancel</button>
  <button
    class="btn btn-success"
    [disabled]="isObjEmpty()"
    (click)="insertEvent()"
  >
    Done
  </button>
</div>

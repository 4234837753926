import {
  Component,
  OnInit,
  ViewChild,
  TemplateRef,
  Output,
  EventEmitter,
  Input,
  AfterViewInit,
} from "@angular/core";
import {
  UntypedFormBuilder,
  Validators,
  UntypedFormGroup,
} from "@angular/forms";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import {
  CalendarOptions,
  DateSelectArg,
  EventClickArg,
  EventApi,
} from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";

import { FullCalendarComponent } from "@fullcalendar/angular";

@Component({
  selector: "app-full-calendar",
  templateUrl: "./full-calendar.component.html",
  styleUrls: ["./full-calendar.component.scss"],
})
export class FullCalendarsComponent implements OnInit, AfterViewInit {
  modalRef?: BsModalRef;
  chatID = localStorage.getItem("chat_id");

  // bread crumb items
  breadCrumbItems: Array<{}>;

  @ViewChild("modalShow") modalShow: TemplateRef<any>;
  @ViewChild("editmodalShow") editmodalShow: TemplateRef<any>;
  @ViewChild("calendar") calendarComponent: FullCalendarComponent;

  @Output() eventsSet = new EventEmitter<any>();
  @Output() eventRemove = new EventEmitter<any>();

  formEditData: UntypedFormGroup;
  submitted = false;
  category: any[];
  newEventDate: any;
  editEvent: any;
  @Input() calendarEvents: any[] = [];
  @Input() calList = {};
  @Input() currentActiveDays: any;
  // event form
  formData: UntypedFormGroup;
  component_ref: any;
  daragedEvent: any;

  @Input() calendarOptions: CalendarOptions;
  currentEvents: EventApi[] = [];

  daysListSlots = {};
  selectedEvent: any;
  removeEvents = {};

  constructor() {}

  ngAfterViewInit(): void {
    const list = document.querySelectorAll(".fc-scrollgrid-sync-inner");
    list.forEach((item) => {
      const oldElement = item.querySelector(".fc-col-header-cell-cushion");
      const newElement = document.createElement("div");
      if (oldElement) {
        newElement.textContent = oldElement.textContent.replace(
          /[0-9]+\/[0-9]+/g,
          ""
        );
        (oldElement as HTMLElement).style.display = "none";
        oldElement.replaceWith(newElement);
      }
      // (item as HTMLElement).style.display = 'none';
    });
  }

  ngOnInit(): void {}
}

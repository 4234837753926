import { Pipe, PipeTransform } from "@angular/core";
import anchorme from "anchorme";

@Pipe({
  name: "multiLine",
})
export class MultiLinePipe implements PipeTransform {
  transform(value: string, numberOfLine?: number): string {
    if (value) {
      if (!numberOfLine) {
        if (value.length > 0) {
          return anchorme({
            input: value,
            // use some options
            options: {
              // all links will be opened in a new window
              // google links will have "google" class
              // bing links will have "bing" class
              // other links will have "other" class
              attributes: (string) => {
                const attributes = {
                  target: "_blank",
                };
                if (string.indexOf("google.com") > -1) {
                  attributes["class"] = "google";
                } else if (string.indexOf("bing.com") > -1) {
                  attributes["class"] = "bing";
                } else {
                  attributes["class"] = "other";
                }
                return attributes;
              },
              exclude: urlObj => {
                // urlObj.encoded = urlObj.encoded.replace(/%25/g, '%');
                return false;
              }
            },
          }).replace(/\n/g, "<br>");
        }
      } else if (numberOfLine > 0) {
        if (value.length > 0) {
          const stringWithNewLineTag = value
            .replace(/\n/g, "<br>")
            .split("<br>");
          const numberOfLines = stringWithNewLineTag.length;
          if (numberOfLines > 1) {
            return `${stringWithNewLineTag[numberOfLine - 1]} ...`;
          }
          return `${stringWithNewLineTag[numberOfLine - 1]}`;
        }
      }
    }
    return "";
  }
}

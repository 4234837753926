<div class="card m-0 bg-transparent">
  <div class="card-body">
    <div class="row d-flex justify-content-between align-items-center mb-3">
      <div class="col-10 d-flex align-items-center">
        <h4
          class="card-title m-0 offcanvas-header-text d-flex align-items-center"
        >
          {{ "GENERALS.ENABLECHATADMIN" | translate }}
          <i
            class="mdi mdi-information-outline font-size-16 ms-1"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Allows users to send message to selected tags."
          ></i>
        </h4>
      </div>
      <div class="col-2 d-flex justify-content-end">
        <ui-switch
          [color]="offcanvasColors.activeColor"
          [switchColor]="offcanvasColors.switchColor"
          [defaultBgColor]="offcanvasColors.bgColor"
          [defaultBoColor]="offcanvasColors.borderColor"
          [checkedTextColor]="offcanvasColors.activeTextColor"
          [uncheckedTextColor]="offcanvasColors.offTextColor"
          uncheckedLabel="Off"
          [checked]="enableChatWithAdmin"
          checkedLabel="On"
          size="small"
          class="me-1"
          (change)="toggleAdminTalk($event)"
        ></ui-switch>
      </div>
    </div>
    <div
      class="row d-flex align-items-center justify-content-between align-items-center mb-3"
    >
      <div>
        <label class="form-label"> Add Tags </label>
        <ng-select
          [items]="page"
          bindLabel="name"
          bindValue="id"
          placeholder="Select Tags"
          [(ngModel)]="talk_admin_tag_values"
          [multiple]="true"
          [disabled]="!enableChatWithAdmin"
          (change)="updatePostTags()"
        >
        </ng-select>
      </div>
    </div>
  </div>
</div>

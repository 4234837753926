import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { Ng2SearchPipeModule } from "ng2-search-filter";
import { TabsModule } from "ngx-bootstrap/tabs";

import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { ModalModule } from "ngx-bootstrap/modal";
import { PaginationModule } from "ngx-bootstrap/pagination";

import { DropzoneModule } from "ngx-dropzone-wrapper";
import { DROPZONE_CONFIG } from "ngx-dropzone-wrapper";
import { DropzoneConfigInterface } from "ngx-dropzone-wrapper";
import { NgSelectModule } from "@ng-select/ng-select";
import { UiSwitchModule } from "ngx-ui-switch";
import { CKEditorModule } from "@ckeditor/ckeditor5-angular";
import { CarouselModule } from "ngx-bootstrap/carousel";
// import { SharedModule } from "src/app/shared/shared.module";
import { NgApexchartsModule } from "ng-apexcharts";
import { SimplebarAngularModule } from "simplebar-angular";
import { TranslateModule } from "@ngx-translate/core";
import { QRCodeModule } from "angularx-qrcode";
import { AccordionModule } from "ngx-bootstrap/accordion";
import { HttpClientModule } from "@angular/common/http";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { ColorPickerModule } from "ngx-color-picker";
import { CollapseModule } from "ngx-bootstrap/collapse";
import { CdkDragPlaceholder, DragDropModule } from "@angular/cdk/drag-drop";
import { UIModule } from "../shared/ui/ui.module";
import { CollectionsSelectorComponent } from "./collections-selector/collections-selector.component";
import { ProductsSelectorComponent } from "./products-selector/products-selector.component";
import { ChatsSelectorComponent } from "./chats-selector/chats-selector.component";
import { CalendarsSelectorComponent } from "./calendars-selector/calendars-selector.component";
import { CouponsSelectorComponent } from "./coupons-selector/coupons-selector.component";
import { UsersSelectorComponent } from "./users-selector/users-selector.component";
import { ExtensionsSelectorComponent } from "./extensions-selector/extensions-selector.component";
import { PollsSelectorComponent } from "./polls-selector/polls-selector.component";
import { StoresSelectorComponent } from "./stores-selector/stores-selector.component";

const config: DropzoneConfigInterface = {
  // Change this to your upload POST address:
  url: "https://httpbin.org/post",
  maxFilesize: 100,
};

@NgModule({
  // tslint:disable-next-line: max-line-length
  declarations: [
    CollectionsSelectorComponent,
    ProductsSelectorComponent,
    ChatsSelectorComponent,
    CalendarsSelectorComponent,
    CouponsSelectorComponent,
    UsersSelectorComponent,
    ExtensionsSelectorComponent,
    PollsSelectorComponent,
    StoresSelectorComponent
  ],
  imports: [
    CommonModule,
    TabsModule.forRoot(),
    ModalModule.forRoot(),
    FormsModule,
    BsDropdownModule.forRoot(),
    DropzoneModule,
    ReactiveFormsModule,
    NgSelectModule,
    PaginationModule.forRoot(),
    UiSwitchModule,
    CKEditorModule,
    UIModule,
    CarouselModule.forRoot(),
    // SharedModule,
    NgApexchartsModule,
    SimplebarAngularModule,
    TranslateModule,
    QRCodeModule,
    AccordionModule.forRoot(),
    BsDatepickerModule.forRoot(),
    HttpClientModule,
    ColorPickerModule,
    CollapseModule.forRoot(),
    CdkDragPlaceholder,
    DragDropModule,
  ],
  providers: [
    {
      provide: DROPZONE_CONFIG,
      useValue: config,
    },
  ],
  exports: [
    CollectionsSelectorComponent,
    ProductsSelectorComponent,
    ChatsSelectorComponent,
    CalendarsSelectorComponent,
    CouponsSelectorComponent,
    UsersSelectorComponent,
    ExtensionsSelectorComponent,
    PollsSelectorComponent,
    StoresSelectorComponent
  ],
})
export class SelectorsModule {}

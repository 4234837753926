import lang from "src/assets/lang.json";
import iso from "src/assets/iso.json";
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { WebsocketService } from "src/app/core/services/websocket.service";
import { Router } from "@angular/router";
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from "@angular/forms";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { ListCollectionItem, ListStore } from "src/app/core/services/outbound";
import {
  store_color_map,
  store_role_map,
  user_role_color_map,
  user_role_map,
  user_search_placeholder_map,
} from "src/app/core/services/constants";

@Component({
  selector: "app-stores-selector",
  templateUrl: "./stores-selector.component.html",
  styleUrls: ["./stores-selector.component.scss"],
  providers: [],
})

/**
 * Contacts domain-list component
 */
export class StoresSelectorComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() mode = "list";
  @Input() single = false;
  @Input() selectedStores = [];
  @Input() className: string[];
  @Output() selectedNotifier = new EventEmitter<any>();
  @Output() cancelNotifier = new EventEmitter<boolean>(false);
  // bread crumb items
  breadCrumbItems: Array<{}>;
  page: Array<any> = [];
  eop = false;
  pageSize = 24;
  currentPage = 0;
  storesList: {};
  storeListSubscription: any;
  monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];
  store_role_map = {};
  store_color_map = {};
  user_search_placeholder_map = {};
  user_role_map = {};
  user_role_color_map = {};
  payload = {};
  storeListForm: UntypedFormGroup;
  submitted = false;
  dupe = false;
  criterionCount = 0;
  component_ref: any;
  search_message = "";
  countries = [];
  loading = false;
  verifySubscription: any;
  my_id: any;
  selectedStoresList = {};

  constructor(
    private ws: WebsocketService,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private mdw: MiddlwareService,
    private ref: ChangeDetectorRef
  ) {}

  ngOnInit() {
    // console.log(this.router.url);
    this.countries = iso.map((a) => a.name);
    this.store_role_map = store_role_map;
    this.store_color_map = store_color_map;
    this.user_role_map = user_role_map;
    this.user_role_color_map = user_role_color_map;
    this.user_search_placeholder_map = user_search_placeholder_map;

    // console.log(this.countries);
    this.component_ref = this.mdw.makeRef(16);
    this.currentPage = 1;
    this.storesList = {};
    // console.log(Object.keys(this.userList).length);

    this.storeListForm = this.formBuilder.group({
      chat_id: new UntypedFormControl(null),
      created_date: new UntypedFormControl(null),
      search_type: new UntypedFormControl("name"),
      search_value: new UntypedFormControl(null),
      category: new UntypedFormControl(""),
    });

    this.storeListSubscription = this.ws.listStoreContainer$.subscribe(
      (listStoresData) => {
        if (listStoresData.ref === this.component_ref) {
          if (listStoresData) {
            this.loading = false;
            this.currentPage = listStoresData.page_number;
            if (listStoresData.data.length == 0 && this.currentPage == 1) {
              this.search_message = "No results matched your search criteria";
            }

            listStoresData.data.forEach((store: any) => {
              store.created_date = new Date(store.created_date);
              store.created_date =
                store.created_date.getDate() +
                " " +
                this.monthNames[store.created_date.getMonth()] +
                ", " +
                store.created_date.getFullYear();
              // store.country_alpha = iso.find((e) => e.code == store.country_code)?.alpha;

              this.storesList[String(store.id)] = store;
            });
            // console.log(Object.keys(this.userList).length);

            this.page = [];
            for (let i = 0; i < this.pageSize; i++) {
              if (
                Object.keys(this.storesList)[
                  i + (this.currentPage - 1) * this.pageSize
                ]
              ) {
                this.page.push(
                  this.storesList[
                    Object.keys(this.storesList)[
                      i + (this.currentPage - 1) * this.pageSize
                    ]
                  ]
                );
              }
            }

            document
              .getElementById("pagination_back")
              .classList.remove("d-none");
            document
              .getElementById("pagination_back")
              .classList.add("enablePointer");
            document
              .getElementById("pagination_next")
              .classList.remove("d-none");
            document
              .getElementById("pagination_next")
              .classList.add("enablePointer");

            this.eop = false;
            if (listStoresData.eop) {
              this.eop = true;
              document
                .getElementById("pagination_next")
                .classList.add("d-none");
              document
                .getElementById("pagination_next")
                .classList.remove("enablePointer");
            } else {
              document
                .getElementById("pagination_next")
                .classList.remove("d-none");
              document
                .getElementById("pagination_next")
                .classList.add("enablePointer");
            }
            if (this.currentPage == 1) {
              document
                .getElementById("pagination_back")
                .classList.add("d-none");
              document
                .getElementById("pagination_back")
                .classList.remove("enablePointer");
            } else {
              document
                .getElementById("pagination_back")
                .classList.remove("d-none");
              document
                .getElementById("pagination_back")
                .classList.add("enablePointer");
            }
            if (this.currentPage == 1 && listStoresData.eop) {
              document
                .getElementById("showPageNumber")
                .classList.add("invisible");
            } else {
              document
                .getElementById("showPageNumber")
                .classList.remove("invisible");
            }
            this.ref.detectChanges();
          }
        }
      }
    );

    this.verifySubscription = this.ws.verifyContainer$.subscribe(
      (verifyData) => {
        // console.log("verifyData"+ verifyData);
        if (verifyData) {
          this.payload["category"] = this.className;
          this.my_id = this.mdw.getID();
          this.loading = true;
          this.ws.processMethod(
            new ListStore(
              0,
              localStorage.getItem("chat_id"),
              this.component_ref,
              this.payload["store_id"],
              this.payload["name"],
              this.payload["created_date"],
              this.payload["category"]
            )
          );
        }
      }
    );

    if (this.selectedStores && this.selectedStores.length > 0) {
      for (let i = 0; this.selectedStores.length > i; i++) {
        this.selectedStoresList[this.selectedStores[i].id] =
          this.selectedStores[i];
      }
    }
  }



  ngAfterViewInit(): void {
    document.getElementById("pagination_back").classList.add("d-none");
    document
      .getElementById("pagination_back")
      .classList.remove("enablePointer");
    document.getElementById("pagination_next").classList.add("d-none");
    document
      .getElementById("pagination_next")
      .classList.remove("enablePointer");
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.storeListForm.controls;
  }

  onSubmit() {
    this.loading = true;

    let id =
      this.f.search_type.value === "collection"
        ? this.f.search_value.value
        : null;
    let name =
      this.f.search_type.value === "name" ? this.f.search_value.value : null;
    let created_date = this.f.created_date.value;
    let category = this.f.category.value;
    this.search(id, name, created_date, category);
  }

  search(id: any, name: any, created_date: any, category: string) {
    this.currentPage = 0;
    this.storesList = {};
    this.payload = {};
    this.search_message = "";

    this.payload = {
      page_size: this.pageSize,
      page_number: 0,
      chat_id: localStorage.getItem("chat_id"),
    };

    if (id != null && id !== "") {
      this.payload["store_id"] = id;
    }
    if (name != null && name !== "") {
      this.payload["name"] = name;
    }
    if (created_date != null && created_date !== "") {
      this.payload["created_date"] = created_date;
    }

    if (category != null && category !== "") {
      this.payload["category"] = [category];
    } else {
      this.payload["category"] = this.className;
    }

    this.ws.processMethod(
      new ListCollectionItem(
        0,
        localStorage.getItem("chat_id"),
        this.component_ref,
        this.payload["store_id"],
        this.payload["name"],
        this.payload["created_date"],
        this.payload["category"]
      )
    );
  }
  resetSearch() {
    this.currentPage = 0;
    this.storesList = {};
    this.search_message = "";

    this.payload["page_size"] = this.pageSize;
    this.payload["page_number"] = this.currentPage;
    this.payload["chat_id"] = localStorage.getItem("chat_id");
    this.payload["category"] = this.className;

    this.ws.processMethod(
      new ListCollectionItem(
        this.currentPage,
        localStorage.getItem("chat_id"),
        this.component_ref,
        this.payload["store_id"],
        this.payload["name"],
        this.payload["created_date"],
        this.payload["category"]
      )
    );
  }

  nextPage() {
    // console.log("request");
    this.payload["page_size"] = this.pageSize;
    this.payload["page_number"] = this.currentPage;
    this.payload["chat_id"] = localStorage.getItem("chat_id");

    this.ws.processMethod(
      new ListCollectionItem(
        this.currentPage,
        localStorage.getItem("chat_id"),
        this.component_ref,
        this.payload["store_id"],
        this.payload["name"],
        this.payload["created_date"],
        this.payload["category"]
      )
    );
  }

  backPage() {
    if (this.currentPage != 1) {
      this.currentPage--;
      this.page = [];
      for (let i = 0; i < this.pageSize; i++) {
        if (
          Object.keys(this.storesList)[
            i + (this.currentPage - 1) * this.pageSize
          ]
        ) {
          this.page.push(
            this.storesList[
              Object.keys(this.storesList)[
                i + (this.currentPage - 1) * this.pageSize
              ]
            ]
          );
        }
      }
      if (this.currentPage == 1) {
        document.getElementById("pagination_back").classList.add("d-none");
        document
          .getElementById("pagination_back")
          .classList.remove("enablePointer");
      }
      if (
        this.currentPage <
        Object.keys(this.storesList).length / this.pageSize
      ) {
        document.getElementById("pagination_next").classList.remove("d-none");
        document
          .getElementById("pagination_next")
          .classList.add("enablePointer");
      }
    }
  }

  editSotore(data, event) {
    event.stopPropagation();
    event.preventDefault();
    if (this.single) {
      if (this.selectedStoresList[data.id]) {
        delete this.selectedStoresList[data.id];
      } else {
        this.selectedStoresList = {};
        this.selectedStoresList[data.id] = data;
      }
    } else {
      if (this.selectedStoresList[data.id]) {
        delete this.selectedStoresList[data.id];
      } else {
        this.selectedStoresList[data.id] = data;
      }
    }
  }

  insertEvent() {
    const selected = Object.values(this.selectedStoresList);
    this.selectedNotifier.emit(selected);
  }

  cancelEvent() {
    this.cancelNotifier.emit(true);
  }

  isObjEmpty() {
    return Object.keys(this.selectedStoresList).length === 0;
  }

  isSelected(id: string) {
    return this.selectedStoresList[id] ? true : false;
  }

  ngOnDestroy(): void {
    // console.log("destroy");
    if (this.storeListSubscription) {
      this.storeListSubscription.unsubscribe();
    }
    if (this.verifySubscription) {
      this.verifySubscription.unsubscribe();
    }
  }
}

import { Injectable } from "@angular/core";
import { MessageTypes } from "../core/services/constants";
import { NgxMasonryComponent } from "ngx-masonry";
import Swal from "sweetalert2";

@Injectable({
  providedIn: "root",
})
export class CommonService {
  masonryComponent: NgxMasonryComponent;
  constructor() {}
  isFileSizeValid(type: string, size: number): boolean {
    if (
      ((type === MessageTypes.VIDEO || type === MessageTypes.DOCUMENT) &&
        size >= 52428800) ||
      (type !== MessageTypes.VIDEO &&
        type !== MessageTypes.DOCUMENT &&
        size >= 20971520)
    ) {
      return false;
    }
    return true;
  }

  resizeMedia(width: number, height: number) {
    const min = 100;
    const max = 280;

    const aspect = width / height;
    if (height < width) {
      if (height < min) {
        height = min;
        width = height * aspect;
      }
    } else if (width < min) {
      width = min;
      height = width / aspect;
    }

    if (height > width) {
      if (height > max) {
        height = max;
        width = height * aspect;
      }
    } else if (width > max) {
      width = max;
      height = width / aspect;
    }

    return [width, height];
  }

  dateFormat(date: string) {
    const now = new Date(date);
    // Get individual components
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const day = String(now.getDate()).padStart(2, "0");

    // Format as YYYY-MM-DD
    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
  }

  fileChecker(file, type) {
    if (!file.name) {
      Swal.fire(
        "Failed",
        "The file you are trying to upload has no name.",
        "error"
      );
      return false;
    } else if (file.size <= 0) {
      Swal.fire(
        "Failed",
        "Kindly note that the file you tried to upload has no content.",
        "error"
      );
      return false;
    } else if (!this.isFileSizeValid(type, file.size)) {
      Swal.fire(
        "Failed",
        "The file you are trying to upload is too large.",
        "error"
      );
      return false;
    } else {
      return true;
    }
  }
}

<div
  *ngIf="loading"
  id="preloader"
  class="bg-transparent d-flex justify-content-center align-items-center"
>
  <div class="spinner-chase">
    <div class="chase-dot"></div>
    <div class="chase-dot"></div>
    <div class="chase-dot"></div>
    <div class="chase-dot"></div>
    <div class="chase-dot"></div>
    <div class="chase-dot"></div>
  </div>
</div>
<div class="container-fluid">
  <div class="row">
    <div class="col-lg-12">
      <div class="card m-0 shadow-none">
        <div class="card-body">
          <form
            [formGroup]="chatsListForm"
            (ngSubmit)="onSubmit()"
            class="mb-3"
          >
            <div class="row g-3">
              <div class="col-xl-4 col-lg-8">
                <div
                  class="input-group"
                  [ngClass]="{
                    'is-invalid': submitted && f.search_value.errors
                  }"
                >
                  <select
                    class="form-select"
                    style="max-width: 120px"
                    formControlName="search_type"
                    (change)="searchTypeChanged()"
                  >
                    <option value="name" selected>
                      {{ "GENERALS.NAME" | translate }}
                    </option>
                    <option [value]="searchTypes?.value">
                      {{ searchTypes?.title | translate }}
                    </option>
                  </select>
                  <input
                    type="text"
                    class="form-control"
                    [placeholder]="
                      user_search_placeholder_map[
                        chatsListForm.get('search_type').value
                      ] | translate
                    "
                    formControlName="search_value"
                  />
                </div>
                <div
                  *ngIf="submitted && f.search_value.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="f.search_value.errors.pattern">
                    {{ "GENERALS.PLEASEENTERVALIDID" | translate }}
                  </div>
                </div>
              </div>
              <div class="col-xl-2 col-lg-4">
                <div class="mb-3">
                  <input
                    class="form-control"
                    [placeholder]="'GENERALS.SELECTDATE' | translate"
                    name="dp"
                    bsDatepicker
                    [bsConfig]="{showWeekNumbers: false}"
                    autocomplete="off"
                    formControlName="created_date"
                  />
                </div>
              </div>
              <div class="col-xl-2 offset-xl-4 col-lg-4 offset-lg-8">
                <button type="submit" class="btn btn-soft-secondary w-100">
                  <i class="bx bx-search-alt align-middle"></i>
                  {{ "GENERALS.SEARCH" | translate }}
                </button>
              </div>
            </div>
          </form>
          <!-- Nav tabs -->

          <div class="row justify-content-center">
            <div class="col-xl-12">
              <div
                [ngStyle]="{ height: mode === 'selector' ? '54vh' : 'auto' }"
              >
                <ng-container *ngIf="!type">
                  <tabset class="nav-tabs nav-tabs-custom col-10">
                    <tab
                      heading="Channels"
                      class="mt-4"
                      (selectTab)="onChanges($event)"
                      style="height: 55vh"
                    >
                      <!-- <ng-template tabHeading>Channels</ng-template> -->
                      <ng-template [ngTemplateOutlet]="Channels"></ng-template>
                    </tab>
                    <tab
                      heading="Groups"
                      class="mt-4"
                      (selectTab)="onChanges($event)"
                      style="height: 55vh"
                    >
                      <!-- <ng-template tabHeading>Groups</ng-template> -->
                      <ng-template [ngTemplateOutlet]="Groups"></ng-template>
                    </tab>
                  </tabset>
                  <ng-template #Channels>
                    <ngx-simplebar id="scroll" class="h-100" #scrollRef>
                      <div class="row g-4 w-100">
                        <div
                          class="col-xxl-2 col-lg-3 col-md-4 col-sm-6"
                          *ngFor="let data of page"
                          (click)="editCollection(data)"
                        >
                          <div
                            class="card p-2 border shadow-none"
                            [ngClass]="{
                              'border-primary': isSelected(data.id)
                            }"
                          >
                            <i
                              class="fas fa-check-circle position-absolute top-0 end-0 m-1 font-size-18 text-primary"
                              *ngIf="isSelected(data.id)"
                            ></i>
                            <div class="p-2">
                              <h5 class="text-truncate">
                                <a class="text-dark">{{ data.name }}</a>
                              </h5>
                              <p class="text-muted mb-0">{{ data.id }}</p>
                            </div>

                            <div
                              style="height: 150px"
                              class="position-relative d-flex align-items-center justify-content-center"
                            >
                              <img
                                src="{{
                                  data.media?.thumbnail?.permanentUrl ||
                                    data.media?.photo?.permanentUrl ||
                                    data.media?.photo?.url
                                }}"
                                class="rounded"
                                style="max-width: 100%; max-height: 100%"
                                onerror="this.onerror=null; this.src='assets/images/group/group-empty.webp'"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </ngx-simplebar>
                  </ng-template>
                  <ng-template #Groups>
                    <ngx-simplebar id="scroll" class="h-100" #scrollRef>
                      <div class="row g-4 w-100">
                        <div
                          class="col-xxl-2 col-lg-3 col-md-4 col-sm-6"
                          *ngFor="let data of page"
                          (click)="editCollection(data)"
                        >
                          <div
                            class="card p-2 border shadow-none"
                            [ngClass]="{
                              'border-primary': isSelected(data.id)
                            }"
                          >
                            <i
                              class="fas fa-check-circle position-absolute top-0 end-0 m-1 font-size-18 text-primary"
                              *ngIf="isSelected(data.id)"
                            ></i>
                            <div class="p-2">
                              <h5 class="text-truncate">
                                <a class="text-dark">{{ data.name }}</a>
                              </h5>
                              <p class="text-muted mb-0">{{ data.id }}</p>
                            </div>

                            <div
                              style="height: 150px"
                              class="position-relative d-flex align-items-center justify-content-center"
                            >
                              <img
                                src="{{
                                  data.media?.thumbnail?.permanentUrl ||
                                    data.media?.photo?.permanentUrl ||
                                    data.media?.photo?.url
                                }}"
                                class="rounded"
                                style="max-width: 100%; max-height: 100%"
                                onerror="this.onerror=null; this.src='assets/images/group/group-empty.webp'"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </ngx-simplebar>
                  </ng-template>
                </ng-container>
                <ng-container *ngIf="type">
                  <ngx-simplebar id="scroll" class="h-100" #scrollRef>
                    <div class="row g-4 w-100">
                      <div
                        class="col-xxl-2 col-lg-3 col-md-4 col-sm-6"
                        *ngFor="let data of page"
                        (click)="editCollection(data)"
                      >
                        <div
                          class="card p-2 border shadow-none"
                          [ngClass]="{
                            'border-primary': isSelected(data.id)
                          }"
                        >
                          <i
                            class="fas fa-check-circle position-absolute top-0 end-0 m-1 font-size-18 text-primary"
                            *ngIf="isSelected(data.id)"
                          ></i>
                          <div class="p-2">
                            <h5 class="text-truncate">
                              <a class="text-dark">{{ data.name }}</a>
                            </h5>
                            <p class="text-muted mb-0">{{ data.id }}</p>
                          </div>

                          <div
                            style="height: 150px"
                            class="position-relative d-flex align-items-center justify-content-center"
                          >
                            <img
                              src="{{
                                data.media?.thumbnail?.permanentUrl ||
                                  data.media?.photo?.permanentUrl ||
                                  data.media?.photo?.url
                              }}"
                              class="rounded"
                              style="max-width: 100%; max-height: 100%"
                              onerror="this.onerror=null; this.src='assets/images/group/group-empty.webp'"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </ngx-simplebar>
                </ng-container>
              </div>
            </div>
          </div>

          <div class="row" *ngIf="search_message">
            <div class="col text-center mt-4 mb-0">
              <h6>
                {{ search_message }}
              </h6>
            </div>
          </div>
          <div class="row">
            <div class="col-5">
              <ul class="ps-0 mt-4">
                <button
                  class="btn btn-outline-secondary d-flex align-items-center pt-0 pb-0 ps-1 p-2"
                  id="pagination_back"
                  (click)="backPage()"
                >
                  <i class="mdi mdi-chevron-left font-size-18"></i>
                  <p class="mb-0">{{ "GENERALS.PREV" | translate }}</p>
                </button>
              </ul>
            </div>
            <div class="col-2 d-flex justify-content-center align-items-center">
              <p class="avatar-xs invisible mb-0" id="showPageNumber">
                <span class="avatar-title rounded-circle bg-primary">
                  {{ currentPage }}
                </span>
              </p>
            </div>
            <div class="col-5">
              <ul class="d-flex justify-content-end ps-0 mt-4">
                <button
                  class="btn btn-outline-secondary d-flex align-items-center pt-0 pb-0 ps-2 pe-1"
                  id="pagination_next"
                  (click)="nextPage()"
                >
                  <p class="mb-0">{{ "GENERALS.NEXT" | translate }}</p>
                  <i class="mdi mdi-chevron-right font-size-18"></i>
                </button>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal-footer">
  <button class="btn btn-danger me-2" (click)="cancelEvent()">Cancel</button>
  <button
    class="btn btn-success"
    [disabled]="isObjEmpty()"
    (click)="insertEvent()"
  >
    Done
  </button>
</div>

<div id="layout-wrapper" >

  <app-topbar (settingsButtonClicked)="onSettingsButtonClicked()" (mobileMenuButtonClicked)="onToggleMobileMenu()"></app-topbar>

  <app-sidebar [isCondensed]="isCondensed" [isHover]="isHover" (mobileMenuHover)="onHoverMobileMenu()"></app-sidebar>

  <div class="main-content vh-100 overflow-y-auto" [ngStyle]="{ 'margin-left': isHover ? '70px' : '' }">
    <div class="page-content pb-0">
      <!-- content -->
        <router-outlet></router-outlet>
    </div>
    <!-- footer -->
    <!-- <app-footer></app-footer> -->
  </div>
</div>

<app-rightsidebar></app-rightsidebar>

import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { ImageUploader, Media } from "src/app/core/services/interface";
import { DeleteMyGallery, GetMyMedia } from "src/app/core/services/outbound";
import { WebsocketService } from "src/app/core/services/websocket.service";
import Axios, { AxiosResponse, AxiosError } from "axios";
import { environment } from "src/environments/environment";
import { DropzoneConfigInterface } from "ngx-dropzone-wrapper";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { BlobService } from "src/app/sharedservices/blob.service";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import Swal from "sweetalert2";

@Component({
  selector: "app-image-uploader",
  templateUrl: "./image-uploader.component.html",
  styleUrls: ["./image-uploader.component.scss"],
})

/**
 * UI-Image-cropper component
 */
export class ImageUploaderComponent implements OnInit, OnDestroy {
  constructor(
    public ws: WebsocketService,
    private formBuilder: UntypedFormBuilder,
    private modalService: BsModalService,
    private blobService: BlobService,
    private mdw: MiddlwareService
  ) {}

  @Input() data: ImageUploader = {
    id: "slider", // pass the id section in case you have multiple uploader in place.
    title: "Slider Images", // title of the components
    max: 5, // from media gallary max select // -1 : unlimited
    aspectRatio: null, // 1x1  only in cropper mode
    resizeToWidth: 0, // only in cropper mode
    resizeToHeight: 0, // only in cropper mode
    cropperView: true, // true: upload image with cropper, false: drop image
    removePadding: false,
    removeButton: false,
    params: null,
    mode: ["upload", "gallery", "url"],
    imageType: "image/jpeg,image/pjpeg,image/png",
    selectedImages: [], // this is the images to be displayed.
  };

  @Input() removePadding = false;
  @Input() bg: string;
  @ViewChild("largeDataModal") myModal;

  @Output() images = new EventEmitter<any[]>();
  @Output() imageSet = new EventEmitter<any>();
  mediaType: string = "image";
  verifySubscription: any;
  myGallerySubscription: any;
  deleteMyGallerySubscription: any;
  mediaData: Media[] = [];
  mediaSelectedImages: any[] = [];
  selectedImages: any[] = [];
  urlForm: UntypedFormGroup;
  submitted = false;
  collapseMedia = true;
  modalRef?: BsModalRef;
  file: any;
  aspectRatio;
  resizeToWidth = 0;
  resizeToHeight = 0;
  orgFile: any;
  uploading = false;
  component_ref: any;
  mediaList: {};
  pageSize = 24;
  currentPage = 0;
  chat_id: string;
  eop = false;
  title: string;
  original: any = { height: 0, width: 0, type: "" };

  public dropzoneConfig: DropzoneConfigInterface = {
    maxFiles: 1,
    resizeQuality: 100,
    resizeWidth: 140,
    resizeHeight: 280,
    thumbnailMethod: "crop",
    resizeMethod: "crop",
    maxFilesize: 2,
    acceptedFiles: "image/jpeg,image/pjpeg,image/png",
    addRemoveLinks: true,
    previewsContainer: false,
  };

  ngOnInit(): void {
    this.component_ref = this.mdw.makeRef(16);
    this.mediaList = {};
    this.currentPage = 1;
    this.chat_id = localStorage.getItem("chat_id");
    if (this.data) {
      if (this.data.aspectRatio) {
        const aspectNumber = this.data.aspectRatio.split("x");
        this.aspectRatio = Number(aspectNumber[0]) / Number(aspectNumber[1]);
      }
      if (this.data.resizeToWidth) {
        this.resizeToWidth = this.data.resizeToWidth;
        this.dropzoneConfig.resizeWidth = this.data.resizeToWidth;
      }
      if (this.data.resizeToHeight) {
        this.resizeToHeight = this.data.resizeToHeight;
        this.dropzoneConfig.resizeHeight = this.data.resizeToHeight;
      }
      this.dropzoneConfig = {
        ...this.dropzoneConfig,
        method: "put",
        headers: {
          "Cache-Control": false,
          "X-TOKEN": localStorage.getItem("token"),
        },
        url: (file) => {
          let url = environment.http_upload_url + "?bin=1&p=1&ct=" + file[0].type;
          if (this.data.params === "splash") {
            url.concat('&type=s');
          }else{
            url.concat('&type=5&cache=1');
          }
          if (this.data.aspectRatio) {
            url.concat(`&ar=${this.data.aspectRatio}`);
          }
          if (this.data.keyword) {
            url.concat(`&kw=${this.data.keyword}`);
          }

          if (this.bg) {
            url.concat(`&bg=${this.bg}`);
          }
          return url
        },
      };
      if (this.data.selectedImages && this.data.selectedImages.length > 0) {
        this.selectedImages = [...this.data.selectedImages];
      }

      if (this.data.removeButton) {
        this.collapseMedia = false;
      }

      if (this.data.title) {
        this.title = this.data.title;
      }
    }
    this.urlForm = this.formBuilder.group({
      imageUrl: new UntypedFormControl("", [
        Validators.required,
        Validators.pattern("(https?://.*.(?:png|jpg))"),
      ]),
    });

    this.myGallerySubscription = this.ws.getMyMediaResponseContainer$.subscribe(
      (myMediaData) => {
        if (myMediaData && myMediaData.ref === this.component_ref) {
          this.currentPage = myMediaData.page_number;
          myMediaData.data.forEach((media: any) => {
            let object: Media;
            if (media.response.media) {
              object = {
                ...media.response.media,
                thumbnail: media.response.thumbnail,
                filename: media.filename,
                id: media.response.file,
              };
            } else if (media.response.url) {
              object = {
                id: media.response.file,
                filename: media.filename,
                url: media.response.url,
              };
            }
            this.mediaList[String(object.filename)] = object;
          });

          this.mediaData = [];
          for (let i = 0; i < this.pageSize; i++) {
            if (
              Object.keys(this.mediaList)[
                i + (this.currentPage - 1) * this.pageSize
              ]
            ) {
              this.mediaData.push(
                this.mediaList[
                  Object.keys(this.mediaList)[
                    i + (this.currentPage - 1) * this.pageSize
                  ]
                ]
              );
            }
          }

          if (!this.collapseMedia) {
            const pagination_back = document.getElementById("pagination_back");
            const pagination_next = document.getElementById("pagination_next");
            if (pagination_back && pagination_next) {
              pagination_back.classList.remove("d-none");
              pagination_back.classList.add("enablePointer");
              pagination_next.classList.remove("d-none");
              pagination_next.classList.add("enablePointer");

              this.eop = false;
              if (myMediaData.eop) {
                this.eop = true;
                pagination_next.classList.add("d-none");
                pagination_next.classList.remove("enablePointer");
              } else {
                pagination_next.classList.remove("d-none");
                pagination_next.classList.add("enablePointer");
              }
              if (this.currentPage == 1) {
                pagination_back.classList.add("d-none");
                pagination_back.classList.remove("enablePointer");
              } else {
                pagination_back.classList.remove("d-none");
                pagination_back.classList.add("enablePointer");
              }
              if (this.currentPage == 1 && myMediaData.eop) {
                document
                  .getElementById("showPageNumber")
                  .classList.add("invisible");
              } else {
                document
                  .getElementById("showPageNumber")
                  .classList.remove("invisible");
              }
            }
          }
        }
      }
    );

    this.deleteMyGallerySubscription =
      this.ws.deleteMyGalleryContainer$.subscribe((deletemyGalleryData) => {
        if (deletemyGalleryData) {
          this.mediaData = this.mediaData.filter(
            (image) => image.id !== deletemyGalleryData.filename
          );
        }
      });

    this.verifySubscription = this.ws.verifyContainer$.subscribe(
      (verifyData) => {
        // console.log("verifyData"+ verifyData);
        if (verifyData && this.data.removeButton) {
          this.ws.processMethod(
            new GetMyMedia(
              this.component_ref,
              0,
              this.mediaType,
              this.chat_id,
              this.data.aspectRatio,
              this.data.keyword
            )
          );
        }
      }
    );
  }

  get f() {
    return this.urlForm.controls;
  }

  base64ToFile(data, filename) {
    const arr = data.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  onUploadImageSuccess(args: any) {

    if (args[1].media) {
      const media: Media = {
        ...args[1].media,
        filename: args[1].file,
        thumbnail: args[1].thumbnail,
        id: args[1].file,
      };
      const id = this.mdw.makeRef(16);
      const mediaData = {
        id,
        media: media,
      };
      this.mediaData.push(media);
      this.selectedImages.push(mediaData);
      if (!this.data.removeButton) {
        this.collapseMedia = true;
      }
      this.uploading = false;
      this.images.emit(this.selectedImages);
    }
  }

  addImages(image, status) {
    if (status) {
      const id = this.mdw.makeRef(16);
      const mediaData = {
        id,
        media: image,
      };
      if (this.data.max == 1) {
        this.mediaSelectedImages = [mediaData];
      } else {
        if (
          this.data.max !=
          this.selectedImages.length + this.mediaSelectedImages.length
        ) {
          this.mediaSelectedImages.push(mediaData);
        }
      }
    } else {
      this.mediaSelectedImages = this.mediaSelectedImages.filter(
        (img) => img.media.id !== image.id
      );
    }
  }

  useSelected() {
    this.selectedImages = [...this.selectedImages, ...this.mediaSelectedImages];
    this.mediaSelectedImages = [];
    if (!this.data.removeButton) {
      this.collapseMedia = !this.collapseMedia;
    }
    this.images.emit(this.selectedImages);
  }

  deleteMyGallarySelected() {
    this.mediaSelectedImages.map((image: any) => {
      this.ws.processMethod(new DeleteMyGallery(image.media.id));
    });
    this.mediaSelectedImages = [];
  }

  deleteSelected(img) {
    this.selectedImages = this.selectedImages.filter(
      (image) => image.id !== img.id
    );
    this.images.emit(this.selectedImages);
  }

  addImageUrl() {
    this.submitted = true;
    if (this.urlForm.invalid) {
    } else {
      var filename = this.urlForm.value.imageUrl.split("/").pop();
      const id = this.mdw.makeRef(16);
      const media: Media = {
        filename: filename,
        permanentUrl: this.urlForm.value.imageUrl,
      };
      const mediaData = {
        id,
        media: media,
      };
      this.selectedImages.push(mediaData);
      this.images.emit(this.selectedImages);
      this.urlForm.reset();
      this.submitted = false;
    }
  }

  isImageSelected(status: boolean) {
    if (status) {
      return {
        border: "2px solid",
      };
    }
  }

  async uploadeImage(file?: any) {
    //upload Chat Image
    if (this.modalRef) {
      this.modalRef.hide();
    }
    this.uploading = true;
    let params: any = {
      p: "1",
      type: "5",
      cache: "1",
    };
    if (this.data.params === "splash") {
      params = {
        p: "1",
        type: "s",
      };
    }
    if (this.data.aspectRatio) {
      params.ar = this.data.aspectRatio;
    }
    if (this.data.keyword) {
      params.kw = this.data.keyword;
    }

    if (this.bg) {
      params.bg = this.bg;
    }

    try {
      await Axios({
        method: "put",
        url: environment.http_upload_url,
        data: file.file,
        headers: {
          "Content-Type": file.type,
          "X-TOKEN": localStorage.getItem("token"),
        },
        params: params,
        responseType: "json",
      })
        .then((response) => {
          if (response.data.media) {
            const media: Media = {
              ...response.data.media,
              filename: response.data.file,
              thumbnail: response.data.thumbnail,
              id: response.data.file,
            };
            const id = this.mdw.makeRef(16);
            const mediaData = {
              id,
              media: media,
            };
            this.mediaData.push(media);
            this.selectedImages.push(mediaData);
            if (!this.data.removeButton) {
              this.collapseMedia = true;
            }
            this.uploading = false;
            this.images.emit(this.selectedImages);
          } else if (response.data.files) {
            const media: Media = {
              ...response.data.files[4].media,
              filename: response.data.files[4].file,
              thumbnail: response.data.files[4].thumbnail,
              id: response.data.files[4].file,
            };
            const id = this.mdw.makeRef(16);
            const mediaData = {
              id,
              media: media,
            };
            this.mediaData.push(media);
            this.selectedImages.push(mediaData);
            if (!this.data.removeButton) {
              this.collapseMedia = true;
            }
            this.uploading = false;
            this.images.emit(this.selectedImages);
            this.imageSet.emit(response.data.files);
          }
        })
        .catch((err) => {
          if (err.response.data.error) {
            this.uploading = false;
          }
        });
    } catch (error) {
      // console.log(error);
      this.uploading = false;
    }
  }

  isRetchTheMax() {
    if (
      this.data.max != 1 &&
      this.data.max ==
        this.selectedImages.length + this.mediaSelectedImages.length
    ) {
      return true;
    } else {
      return false;
    }
  }
  async onFileAdded(event, modal) {
    // if(event.status == 'success'){
    const file = event.target.files[0];
    // Encode the file using the FileReader API
    if (file != null) {
      const blopFile = await this.blobService.createBlobFile(file);
      const blopUrl = await this.blobService.getblobURL(blopFile);
      this.blobService.getImageMetadata(blopUrl).then((imageMetadata: any) => {
        this.orgFile = file;
        this.original.height = imageMetadata.height;
        this.original.width = imageMetadata.width;
        this.original.type = file.type;

        if (!this.aspectRatio) {
          this.aspectRatio = imageMetadata.width / imageMetadata.height;
        }
        if (imageMetadata.height >= imageMetadata.width) {
          if (this.resizeToHeight == 0) {
            if (file.height > 512) {
              this.resizeToHeight = 512;
            } else {
              this.resizeToHeight = imageMetadata.height;
            }
          }
        } else {
          if (this.resizeToWidth == 0) {
            if (imageMetadata.width > 512) {
              this.resizeToWidth = 512;
            } else {
              this.resizeToWidth = imageMetadata.width;
            }
          }
        }

        if (file.type && !this.data.outputFormat) {
          switch (file.type) {
            case "image/png":
              this.data.outputFormat = "png";
              break;
            default:
              this.data.outputFormat = "jpeg";
              break;
          }
        }
        if (
          imageMetadata.width >= this.resizeToWidth ||
          imageMetadata.height >= this.resizeToHeight
        ) {
          this.largeModal(modal);
        } else {
          const reader: any = new FileReader();
          reader.readAsArrayBuffer(file);
          reader.onloadend = (event) => {
            this.file = {
              file: event.target.result,
              width: imageMetadata.width,
              height: imageMetadata.height,
              type: file.type,
            };

            this.uploadeImage(this.file);
          };
        }
      });
    }
    // }
  }

  imageCropped(event) {
    this.file = {
      file: event.blob,
      width: event.width,
      height: event.height,
      type: event.blob.type,
    };
  }

  imageLoaded(event) {}

  largeModal(largeDataModal: any) {
    this.modalRef = this.modalService.show(largeDataModal, {
      class: "modal-lg",
    });
  }

  isMediaSelected(media) {
    return (
      this.mediaSelectedImages.filter((res) => res.media.id === media.id)
        .length > 0
    );
  }

  nextPage() {
    this.ws.processMethod(
      new GetMyMedia(
        this.component_ref,
        this.currentPage,
        this.mediaType,
        this.chat_id,
        this.data.aspectRatio,
        this.data.keyword
      )
    );
  }

  backPage() {
    if (this.currentPage != 1) {
      this.currentPage--;
      this.mediaData = [];
      for (let i = 0; i < this.pageSize; i++) {
        if (
          Object.keys(this.mediaList)[
            i + (this.currentPage - 1) * this.pageSize
          ]
        ) {
          this.mediaData.push(
            this.mediaList[
              Object.keys(this.mediaList)[
                i + (this.currentPage - 1) * this.pageSize
              ]
            ]
          );
        }
      }
      if (this.currentPage == 1) {
        document.getElementById("pagination_back").classList.add("d-none");
        document
          .getElementById("pagination_back")
          .classList.remove("enablePointer");
      }
      if (
        this.currentPage <
        Object.keys(this.mediaList).length / this.pageSize
      ) {
        document.getElementById("pagination_next").classList.remove("d-none");
        document
          .getElementById("pagination_next")
          .classList.add("enablePointer");
      }
    }
  }

  openImagesUploder() {
    this.collapseMedia = !this.collapseMedia;
    this.ws.processMethod(
      new GetMyMedia(
        this.component_ref,
        0,
        this.mediaType,
        this.chat_id,
        this.data.aspectRatio
      )
    );
  }

  fileAdded(file: any) {
    this.uploading = true;
  }
  fileError(file) {
    this.uploading = false;
    Swal.fire("File Size!", file[1], "error");
  }

  ngOnDestroy(): void {
    if (this.verifySubscription) {
      this.verifySubscription.unsubscribe();
    }
    if (this.myGallerySubscription) {
      this.myGallerySubscription.unsubscribe();
    }
    if (this.deleteMyGallerySubscription) {
      this.deleteMyGallerySubscription.unsubscribe();
    }
  }
}

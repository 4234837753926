<div
  *ngIf="loading"
  id="preloader"
  class="bg-transparent d-flex justify-content-center align-items-center"
>
  <div class="spinner-chase">
    <div class="chase-dot"></div>
    <div class="chase-dot"></div>
    <div class="chase-dot"></div>
    <div class="chase-dot"></div>
    <div class="chase-dot"></div>
    <div class="chase-dot"></div>
  </div>
</div>
<div class="container-fluid">
  <div class="row">
    <div class="col-lg-12">
      <div class="card m-0 shadow-none">
        <div class="card-body">
          <form
            [formGroup]="storeListForm"
            (ngSubmit)="onSubmit()"
            class="mb-3"
          >
            <div class="row g-2">
              <div class="col-xl-4 col-lg-8">
                <div class="input-group">
                  <select
                    class="form-select"
                    style="max-width: 120px"
                    formControlName="search_type"
                  >
                    <option value="name" selected>
                      {{ "GENERALS.NAME" | translate }}
                    </option>
                    <option value="collection">
                      {{ "COLLECTIONS.COLLECTION" | translate }}
                    </option>
                  </select>
                  <input
                    type="text"
                    class="form-control"
                    [placeholder]="
                      user_search_placeholder_map[
                        storeListForm.get('search_type').value
                      ] | translate
                    "
                    formControlName="search_value"
                  />
                </div>
              </div>
              <div class="col-lg-4 col-xl-2">
                <select class="form-control select2" formControlName="category">
                  <ng-container *ngIf="className && className.length > 0">
                    <option value="">
                      {{ "GENERALS.SELECTTYPE" | translate }}
                    </option>
                    <option *ngIf="className.includes('store')" value="store">
                      {{ "STORES.STORES" | translate }}
                    </option>
                    <option
                      *ngIf="className.includes('booking')"
                      value="booking"
                    >
                      {{ "PRODUCTS.BOOKING" | translate }}
                    </option>
                    <option *ngIf="className.includes('event')" value="event">
                      {{ "PRODUCTS.EVENT" | translate }}
                    </option>
                  </ng-container>
                </select>
              </div>
              <div class="col-xl-2 col-lg-4">
                <div class="mb-3">
                  <input
                    class="form-control"
                    [placeholder]="'GENERALS.SELECTDATE' | translate"
                    name="dp"
                    bsDatepicker
                    [bsConfig]="{ showWeekNumbers: false }"
                    autocomplete="off"
                    formControlName="created_date"
                  />
                </div>
              </div>
              <div class="col-lg-4 offset-lg-4 col-xl-2 offset-xl-2">
                <button type="submit" class="btn btn-soft-secondary w-100">
                  <i class="bx bx-search-alt align-middle"></i>
                  {{ "GENERALS.SEARCH" | translate }}
                </button>
              </div>
            </div>
          </form>
          <!-- Nav tabs -->

          <div
            class="row justify-content-center"
            *ngIf="page && page.length > 0"
          >
            <div class="col-xl-12">
              <div
                [ngStyle]="{ height: mode === 'selector' ? '60vh' : 'auto' }"
              >
                <ngx-simplebar
                  data-simplebar="init"
                  class="simplebar-scrollable-x"
                >
                  <div class="table-responsive">
                    <table
                      class="table table-striped align-middle table-nowrap table-hover"
                    >
                      <thead class="table-light">
                        <tr>
                          <th scope="col" class="text-center rounded-top-4 rounded-end-0">#</th>
                          <th scope="col">{{ "GENERALS.NAME" | translate }}</th>
                          <th scope="col" class="text-center">
                            {{ "STORES.STOREID" | translate }}
                          </th>
                          <th scope="col">
                            {{ "GENERALS.CREATEDDATE" | translate }}
                          </th>
                          <th scope="col" class="text-center">
                            {{ "STORES.STOREDEFAULT" | translate }}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let data of page; let index = index;" class="curser-pointer" (click)="editSotore(data, $event)">
                          <td>
                            <div class="form-check font-size-16">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                id="transactionCheck{{ index }}"
                                [checked]="isSelected(data.id)"
                                (change)="editSotore(data)"
                              />
                              <label
                                class="form-check-label"
                                for="transactionCheck{{ index }}"
                              ></label>
                            </div>
                          </td>
                          <td>
                            <p class="mb-0 fw-bold">{{ data.name }}</p>
                          </td>
                          <td class="text-center">
                            <p class="mb-0 font-size-14 fw-bold">
                              {{ data.id }}
                            </p>
                          </td>
                          <td>
                            <p class="mb-0 font-size-14 fw-bold">
                              {{ data.created_date }}
                            </p>
                          </td>

                          <td class="text-center">
                            <p
                              class="badge font-size-12 mb-0 rounded-pill"
                              ngClass="{{ store_color_map[data.is_default] }}"
                            >
                              {{ store_role_map[data.is_default] | translate }}
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </ngx-simplebar>
              </div>
            </div>
          </div>

          <div class="row" *ngIf="page.length === 0">
            <div class="col text-center mt-4 mb-0">
              <h6>
                {{ search_message }}
              </h6>
            </div>
          </div>
          <div class="row">
            <div class="col-5">
              <ul class="ps-0 mt-4">
                <button
                  class="btn btn-outline-secondary d-flex align-items-center pt-0 pb-0 ps-1 p-2"
                  id="pagination_back"
                  (click)="backPage()"
                >
                  <i class="mdi mdi-chevron-left font-size-18"></i>
                  <p class="mb-0">{{ "GENERALS.PREV" | translate }}</p>
                </button>
              </ul>
            </div>
            <div class="col-2 d-flex justify-content-center align-items-center">
              <p class="avatar-xs invisible mb-0" id="showPageNumber">
                <span class="avatar-title rounded-circle bg-primary">
                  {{ currentPage }}
                </span>
              </p>
            </div>
            <div class="col-5">
              <ul class="d-flex justify-content-end ps-0 mt-4">
                <button
                  class="btn btn-outline-secondary d-flex align-items-center pt-0 pb-0 ps-2 pe-1"
                  id="pagination_next"
                  (click)="nextPage()"
                >
                  <p class="mb-0">{{ "GENERALS.NEXT" | translate }}</p>
                  <i class="mdi mdi-chevron-right font-size-18"></i>
                </button>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal-footer">
  <button class="btn btn-danger me-2" (click)="cancelEvent()">Cancel</button>
  <button
    class="btn btn-success"
    [disabled]="isObjEmpty()"
    (click)="insertEvent()"
  >
    Done
  </button>
</div>

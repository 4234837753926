import lang from "src/assets/lang.json";
import iso from "src/assets/iso.json";
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { WebsocketService } from "src/app/core/services/websocket.service";
import { Router } from "@angular/router";
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { listChat } from "src/app/core/services/outbound";
import {
  ChatTypes,
  user_role_color_map,
  user_role_map,
  user_search_placeholder_map,
  user_status_color_map,
  user_status_map,
} from "src/app/core/services/constants";
import { CommonService } from "src/app/sharedservices/common.service";

@Component({
  selector: "app-chats-selector",
  templateUrl: "./chats-selector.component.html",
  styleUrls: ["./chats-selector.component.scss"],
})

/**
 * Contacts domain-list component
 */
export class ChatsSelectorComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() mode = "list";
  @Input() single = false;
  @Input() selectedChats = [];
  @Input() type: string;
  @Output() selectedNotifier = new EventEmitter<any>();
  @Output() unSelectedNotifier = new EventEmitter<any>();
  @Output() cancelNotifier = new EventEmitter<boolean>(false);
  // bread crumb items
  breadCrumbItems: Array<{}>;
  page: Array<any> = [];
  eop = false;
  pageSize = 24;
  currentPage = 0;
  chatList: {};
  chatListSubscription: any;
  addChatMemberSubscription: any;
  monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];
  status_map = {};
  status_color_map = {};
  user_search_placeholder_map = {};
  user_role_map = {};
  user_role_color_map = {};
  payload = {};
  chatsListForm: UntypedFormGroup;
  submitted = false;
  dupe = false;
  criterionCount = 0;
  component_ref: any;
  search_message = "";
  countries = [];
  loading = false;
  verifySubscription: any;
  my_id: any;
  selectedChatsList = {};
  chatTypes: typeof ChatTypes = ChatTypes;
  searchTypes;

  constructor(
    private ws: WebsocketService,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private mdw: MiddlwareService,
    private ref: ChangeDetectorRef,
    private commonService: CommonService
  ) {}

  ngOnInit() {
    // console.log(this.router.url);
    this.countries = iso.map((a) => a.name);
    this.status_map = user_status_map;
    this.status_color_map = user_status_color_map;
    this.user_role_map = user_role_map;
    this.user_role_color_map = user_role_color_map;
    this.user_search_placeholder_map = user_search_placeholder_map;

    // console.log(this.countries);
    this.component_ref = this.mdw.makeRef(16);
    this.currentPage = 1;
    this.chatList = {};
    // console.log(Object.keys(this.userList).length);
    switch (this.type) {
      case this.chatTypes.CHANNEL:
        this.searchTypes = {
          value: "channel",
          title: "CHANNELS.CHANNEL",
        };
        break;
      case this.chatTypes.GROUP:
        this.searchTypes = {
          value: "group",
          title: "GROUPS.GROUP",
        };
        break;
    }

    this.chatsListForm = this.formBuilder.group({
      chat_id: new UntypedFormControl(null),
      created_date: new UntypedFormControl(null),
      search_type: new UntypedFormControl("name"),
      search_value: new UntypedFormControl(null),
    });

    this.chatListSubscription = this.ws.listChatsContainer$.subscribe(
      (chatData) => {
        if (chatData.ref === this.component_ref) {
          if (chatData) {
            this.loading = false;
            // console.log("in");
            // console.log(Object.keys(this.userList).length);
            this.currentPage = chatData.page_number;
            if (chatData.chats.length == 0 && this.currentPage == 1) {
              this.search_message = "No results matched your search criteria";
            }

            chatData.chats.forEach((chat: any) => {
              chat.created_date = new Date(chat.created_date);
              chat.created_date =
                chat.created_date.getDate() +
                " " +
                this.monthNames[chat.created_date.getMonth()] +
                ", " +
                chat.created_date.getFullYear();
              // chat.country_alpha = iso.find((e) => e.code == chat.country_code)?.alpha;

              this.chatList[String(chat.id)] = chat;
            });
            // console.log(Object.keys(this.userList).length);

            this.page = [];
            for (let i = 0; i < this.pageSize; i++) {
              if (
                Object.keys(this.chatList)[
                  i + (this.currentPage - 1) * this.pageSize
                ]
              ) {
                this.page.push(
                  this.chatList[
                    Object.keys(this.chatList)[
                      i + (this.currentPage - 1) * this.pageSize
                    ]
                  ]
                );
              }
            }

            document
              .getElementById("pagination_back")
              .classList.remove("d-none");
            document
              .getElementById("pagination_back")
              .classList.add("enablePointer");
            document
              .getElementById("pagination_next")
              .classList.remove("d-none");
            document
              .getElementById("pagination_next")
              .classList.add("enablePointer");

            this.eop = false;
            if (chatData.eop) {
              this.eop = true;
              document
                .getElementById("pagination_next")
                .classList.add("d-none");
              document
                .getElementById("pagination_next")
                .classList.remove("enablePointer");
            } else {
              document
                .getElementById("pagination_next")
                .classList.remove("d-none");
              document
                .getElementById("pagination_next")
                .classList.add("enablePointer");
            }
            if (this.currentPage == 1) {
              document
                .getElementById("pagination_back")
                .classList.add("d-none");
              document
                .getElementById("pagination_back")
                .classList.remove("enablePointer");
            } else {
              document
                .getElementById("pagination_back")
                .classList.remove("d-none");
              document
                .getElementById("pagination_back")
                .classList.add("enablePointer");
            }
            if (this.currentPage == 1 && chatData.eop) {
              document
                .getElementById("showPageNumber")
                .classList.add("invisible");
            } else {
              document
                .getElementById("showPageNumber")
                .classList.remove("invisible");
            }
            this.ref.detectChanges();
          }
        }
      }
    );

    this.verifySubscription = this.ws.verifyContainer$.subscribe(
      (verifyData) => {
        // console.log("verifyData"+ verifyData);
        if (verifyData && this.mode === "selector") {
          this.my_id = this.mdw.getID();
          this.loading = true;
          this.payload["type"] = this.type ? this.type : this.chatTypes.CHANNEL;
          switch (this.payload["type"]) {
            case this.chatTypes.CHANNEL:
              this.searchTypes = {
                value: "channel",
                title: "CHANNELS.CHANNEL",
              };
              break;
            case this.chatTypes.GROUP:
              this.searchTypes = {
                value: "group",
                title: "GROUPS.GROUP",
              };
              break;
          }
          this.ws.processMethod(
            new listChat(
              this.payload["type"],
              0,
              localStorage.getItem("chat_id"),
              this.component_ref
            )
          );
        }
      }
    );

    if (this.selectedChats && this.selectedChats.length > 0) {
      for (let i = 0; this.selectedChats.length > i; i++) {
        this.selectedChatsList[this.selectedChats[i].id] =
          this.selectedChats[i];
      }
    }

    this.breadCrumbItems = [
      { label: "MENUITEMS.STOREMANGER.TEXT" },
      { label: "COLLECTIONS.COLLECTIONS", active: true },
    ];
  }

  ngOnDestroy(): void {
    // console.log("destroy");
    if (this.chatListSubscription) {
      this.chatListSubscription.unsubscribe();
    }
    if (this.verifySubscription) {
      this.verifySubscription.unsubscribe();
    }
  }

  ngAfterViewInit(): void {
    document.getElementById("pagination_back").classList.add("d-none");
    document
      .getElementById("pagination_back")
      .classList.remove("enablePointer");
    document.getElementById("pagination_next").classList.add("d-none");
    document
      .getElementById("pagination_next")
      .classList.remove("enablePointer");
  }

  openDetails(data: any) {
    this.router.navigate([
      "/" +
        data.account_id +
        "/" +
        data.ref_domain +
        "/" +
        data.service.product,
    ]);
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.chatsListForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    if (this.chatsListForm.valid) {
      this.loading = true;
      // console.log(this.f.search_value.value);
      let id =
        this.f.search_type.value === this.searchTypes.value
          ? this.f.search_value.value
          : null;
      let name =
        this.f.search_type.value === "name" ? this.f.search_value.value : null;
      let created_date = this.f.created_date.value
        ? this.commonService.dateFormat(this.f.created_date.value)
        : null;
      this.search(id, name, created_date);
    } else {
      return;
    }
  }

  search(id: any, name: any, created_date: any) {
    this.currentPage = 0;
    this.chatList = {};
    // this.payload = {};
    this.search_message = "";

    this.payload = {
      ...this.payload,
      page_size: this.pageSize,
      page_number: 0,
      chat_id: localStorage.getItem("chat_id"),
    };

    if (id != null && id !== "") {
      this.payload["group_id"] = id;
    }else{
      delete this.payload["group_id"];
    }
    if (name != null && name !== "") {
      this.payload["name"] = name;
    }else{
      delete this.payload["name"];
    }
    if (created_date != null && created_date !== "") {
      this.payload["created_date"] = created_date;
    }else{
      delete this.payload["created_date"];
    }
    this.ws.processMethod(
      new listChat(
        this.payload["type"],
        0,
        localStorage.getItem("chat_id"),
        this.component_ref,
        this.payload["group_id"],
        this.payload["name"],
        this.payload["created_date"]
      )
    );
  }

  resetSearch() {
    this.currentPage = 0;
    this.chatList = {};
    this.search_message = "";

    this.payload["page_size"] = this.pageSize;
    this.payload["page_number"] = this.currentPage;
    this.payload["chat_id"] = localStorage.getItem("chat_id");

    this.ws.processMethod(
      new listChat(
        this.payload["type"],
        this.currentPage,
        localStorage.getItem("chat_id"),
        this.component_ref,
        this.payload["group_id"],
        this.payload["name"],
        this.payload["created_date"]
      )
    );
  }

  nextPage() {
    this.ws.processMethod(
      new listChat(
        this.payload["type"],
        this.currentPage,
        localStorage.getItem("chat_id"),
        this.component_ref,
        this.payload["group_id"],
        this.payload["name"],
        this.payload["created_date"]
      )
    );
  }

  backPage() {
    if (this.currentPage != 1) {
      this.currentPage--;
      this.page = [];
      for (let i = 0; i < this.pageSize; i++) {
        if (
          Object.keys(this.chatList)[i + (this.currentPage - 1) * this.pageSize]
        ) {
          this.page.push(
            this.chatList[
              Object.keys(this.chatList)[
                i + (this.currentPage - 1) * this.pageSize
              ]
            ]
          );
        }
      }
      if (this.currentPage == 1) {
        document.getElementById("pagination_back").classList.add("d-none");
        document
          .getElementById("pagination_back")
          .classList.remove("enablePointer");
      }
      if (
        this.currentPage <
        Object.keys(this.chatList).length / this.pageSize
      ) {
        document.getElementById("pagination_next").classList.remove("d-none");
        document
          .getElementById("pagination_next")
          .classList.add("enablePointer");
      }
    }
  }

  abbreviateNumber(value: any) {
    var newValue = value;
    if (value >= 1000) {
      var suffixes = ["", "K", "M", "B", "T"];
      var suffixNum = Math.floor(("" + value).length / 3);
      var shortValue: any;
      for (var precision = 2; precision >= 1; precision--) {
        shortValue = parseFloat(
          (suffixNum != 0
            ? value / Math.pow(1000, suffixNum)
            : value
          ).toPrecision(precision)
        );
        var dotLessShortValue = (shortValue + "").replace(
          /[^a-zA-Z 0-9]+/g,
          ""
        );
        if (dotLessShortValue.length <= 2) {
          break;
        }
      }
      if (shortValue % 1 != 0) shortValue = shortValue.toFixed(1);
      newValue = shortValue + suffixes[suffixNum];
    }
    return newValue;
  }

  getCountryName(alpha: string) {
    return iso.find((e) => e.alpha === alpha)?.name;
  }

  getPercent(frac: number) {
    return (frac * 100).toFixed(2).replace(/[.,]00$/, "") + "%";
  }

  getLangName(code: string) {
    return lang.find((e) => e.code === code)?.native;
  }

  checkUncheckAll(ev: any) {}

  openForm() {
    this.router.navigate(["/collections/new"]);
  }

  editCollection(data) {
    if (this.single) {
      if (this.selectedChatsList[data.id]) {
        delete this.selectedChatsList[data.id];
      } else {
        this.selectedChatsList = {};
        this.selectedChatsList[data.id] = data;
      }
    } else {
      if (this.selectedChatsList[data.id]) {
        delete this.selectedChatsList[data.id];
      } else {
        this.selectedChatsList[data.id] = data;
      }
    }
  }

  onChanges(e: any) {
    this.loading = true;
    this.search_message = "";
    this.payload = {};
    this.currentPage = 0;
    if (e.heading === "Channels") {
      this.component_ref = this.mdw.makeRef(16);
      this.chatList = {};
      this.payload["type"] = this.chatTypes.CHANNEL;
      this.searchTypes = {
        value: "channel",
        title: "CHANNELS.CHANNEL",
      };
      this.chatsListForm.patchValue({
        search_type: 'name'
      });
      this.ws.processMethod(
        new listChat(
          this.payload["type"],
          0,
          localStorage.getItem("chat_id"),
          this.component_ref
        )
      );
    }

    if (e.heading === "Groups") {
      this.component_ref = this.mdw.makeRef(16);
      this.chatList = {};
      this.payload["type"] = this.chatTypes.GROUP;
      this.searchTypes = {
        value: "group",
        title: "GROUPS.GROUP",
      };
      this.chatsListForm.patchValue({
        search_type: 'name'
      });
      this.ws.processMethod(
        new listChat(
          this.payload["type"],
          0,
          localStorage.getItem("chat_id"),
          this.component_ref
        )
      );
    }
  }

  insertEvent() {
    const selected = Object.values(this.selectedChatsList);
    this.selectedNotifier.emit(selected);
  }

  cancelEvent() {
    this.cancelNotifier.emit(true);
  }

  isObjEmpty() {
    return Object.keys(this.selectedChatsList).length === 0;
  }

  isSelected(id: string) {
    return this.selectedChatsList[id] ? true : false;
  }

  searchTypeChanged() {
    if (this.chatsListForm.value.search_type === this.searchTypes.value) {
      this.chatsListForm
        .get("search_value")
        .setValidators([Validators.pattern(/^[0-9]*$/)]);
    } else {
      this.chatsListForm.get("search_value").clearValidators();
    }
    this.chatsListForm.get("search_value").updateValueAndValidity();
  }
}

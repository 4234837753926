<div class="card m-0 bg-transparent">
  <div class="card-body">
    <form class="needs-validation" [formGroup]="selectform">
      <label class="offcanvas-header-text w-100" style="text-align: left">
        Chat Label
      </label>
      <select class="form-select offcanvas-primary" formControlName="label" (change)="selectOption()">
        <ng-container *ngFor="let item of chat_label">
          <option [value]="item?.id">
            {{ item?.name }}
          </option>
        </ng-container>
      </select>
    </form>
  </div>
</div>

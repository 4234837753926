<div class="form-control input-rounded input-focus-border d-flex align-items-center flex-wrap">
  <span class="badge badge-soft-primary font-size-13 d-flex align-items-center my-1 me-1" *ngFor="let key of values; let index = index">
    {{ key }}
    <button
      type="button"
      class="btn btn-sm btn-close ms-1"
      (click)="removeKeyword($event, index)"
    ></button>
  </span>
  <input
    type="text"
    class="form-control border-0 flex-grow-1 p-0"
    style="width: auto;"
    [placeholder]="placeholderText"
    (keydown.enter)="addKeyword($event)"
  />
</div>

import lang from "src/assets/lang.json";
import iso from "src/assets/iso.json";
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { WebsocketService } from "src/app/core/services/websocket.service";
import { Router } from "@angular/router";
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from "@angular/forms";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { ListCollectionItem, ListPoll } from "src/app/core/services/outbound";
import {
  user_role_color_map,
  user_role_map,
  user_search_placeholder_map,
  user_status_color_map,
  user_status_map,
} from "src/app/core/services/constants";

@Component({
  selector: "app-polls-selector",
  templateUrl: "./polls-selector.component.html",
  styleUrls: ["./polls-selector.component.scss"],
  providers: [],
})

/**
 * Contacts domain-list component
 */
export class PollsSelectorComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() mode = "list";
  @Input() single = false;
  @Input() selectedPoll = [];
  @Output() selectedNotifier = new EventEmitter<any>();
  @Output() cancelNotifier = new EventEmitter<boolean>(false);
  // bread crumb items
  breadCrumbItems: Array<{}>;
  page: Array<any> = [];
  eop = false;
  pageSize = 24;
  currentPage = 0;
  pollList: {};
  pollListSubscription: any;
  monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];
  status_map = {};
  status_color_map = {};
  user_search_placeholder_map = {};
  user_role_map = {};
  user_role_color_map = {};
  payload = {};
  pollListForm: UntypedFormGroup;
  submitted = false;
  dupe = false;
  criterionCount = 0;
  component_ref: any;
  search_message = "";
  countries = [];
  loading = false;
  verifySubscription: any;
  my_id: any;
  selectedCollectionsList = {};

  constructor(
    private ws: WebsocketService,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private mdw: MiddlwareService,
    private ref: ChangeDetectorRef
  ) {}

  ngOnInit() {
    // console.log(this.router.url);
    this.countries = iso.map((a) => a.name);
    this.status_map = user_status_map;
    this.status_color_map = user_status_color_map;
    this.user_role_map = user_role_map;
    this.user_role_color_map = user_role_color_map;
    this.user_search_placeholder_map = user_search_placeholder_map;

    // console.log(this.countries);
    this.component_ref = this.mdw.makeRef(16);
    this.currentPage = 1;
    this.pollList = {};
    // console.log(Object.keys(this.userList).length);

    this.pollListForm = this.formBuilder.group({
      member_id: new UntypedFormControl(null),
      search_value: new UntypedFormControl(null),
      status: new UntypedFormControl(""),
      date: new UntypedFormControl(null),
      search_type: new UntypedFormControl("name"),
    });

    this.pollListSubscription = this.ws.listPollContainer$.subscribe(
      (listPoll) => {
        if (listPoll.ref === this.component_ref) {
          // console.log("hit");
          if (listPoll) {
            this.loading = false;
            // console.log("in");
            // console.log(Object.keys(this.pollList).length);
            this.currentPage = listPoll.page_number;
            if (listPoll.data.length == 0 && this.currentPage == 1) {
              this.search_message = "No results matched your search criteria";
            }

            listPoll.data.forEach((poll: any) => {
              poll.created_date = new Date(poll.created_date);
              poll.created_date =
                poll.created_date.getDate() +
                " " +
                this.monthNames[poll.created_date.getMonth()] +
                ", " +
                poll.created_date.getFullYear();

              this.pollList[String(poll.id)] = poll;
            });

            this.page = [];
            for (let i = 0; i < this.pageSize; i++) {
              if (
                Object.keys(this.pollList)[
                  i + (this.currentPage - 1) * this.pageSize
                ]
              ) {
                this.page.push(
                  this.pollList[
                    Object.keys(this.pollList)[
                      i + (this.currentPage - 1) * this.pageSize
                    ]
                  ]
                );
              }
            }

            document
              .getElementById("pagination_back")
              .classList.remove("d-none");
            document
              .getElementById("pagination_back")
              .classList.add("enablePointer");
            document
              .getElementById("pagination_next")
              .classList.remove("d-none");
            document
              .getElementById("pagination_next")
              .classList.add("enablePointer");

            this.eop = false;
            if (listPoll.eop) {
              this.eop = true;
              document
                .getElementById("pagination_next")
                .classList.add("d-none");
              document
                .getElementById("pagination_next")
                .classList.remove("enablePointer");
            } else {
              document
                .getElementById("pagination_next")
                .classList.remove("d-none");
              document
                .getElementById("pagination_next")
                .classList.add("enablePointer");
            }
            if (this.currentPage == 1) {
              document
                .getElementById("pagination_back")
                .classList.add("d-none");
              document
                .getElementById("pagination_back")
                .classList.remove("enablePointer");
            } else {
              document
                .getElementById("pagination_back")
                .classList.remove("d-none");
              document
                .getElementById("pagination_back")
                .classList.add("enablePointer");
            }
            if (this.currentPage == 1 && listPoll.eop) {
              document
                .getElementById("showPageNumber")
                .classList.add("invisible");
            } else {
              document
                .getElementById("showPageNumber")
                .classList.remove("invisible");
            }
            this.ref.detectChanges();
          }
        }
      }
    );

    this.verifySubscription = this.ws.verifyContainer$.subscribe(
      (verifyData) => {
        // console.log("verifyData"+ verifyData);
        if (
          verifyData &&
          (this.router.url === "/collections" || this.mode === "selector")
        ) {
          this.my_id = this.mdw.getID();
          this.loading = true;
          this.ws.processMethod(
            new ListPoll(0, localStorage.getItem("chat_id"), this.component_ref)
          );
        }
      }
    );

    if (this.selectedPoll && this.selectedPoll.length > 0) {
      for (let i = 0; this.selectedPoll.length > i; i++) {
        this.selectedCollectionsList[this.selectedPoll[i].id] =
          this.selectedPoll[i];
      }
    }

    this.breadCrumbItems = [
      { label: "MENUITEMS.STOREMANGER.TEXT" },
      { label: "COLLECTIONS.COLLECTIONS", active: true },
    ];
  }

  ngOnDestroy(): void {
    // console.log("destroy");
    if (this.pollListSubscription) {
      this.pollListSubscription.unsubscribe();
    }
    if (this.verifySubscription) {
      this.verifySubscription.unsubscribe();
    }
  }

  ngAfterViewInit(): void {
    document.getElementById("pagination_back").classList.add("d-none");
    document
      .getElementById("pagination_back")
      .classList.remove("enablePointer");
    document.getElementById("pagination_next").classList.add("d-none");
    document
      .getElementById("pagination_next")
      .classList.remove("enablePointer");
  }

  openDetails(data: any) {
    this.router.navigate([
      "/" +
        data.account_id +
        "/" +
        data.ref_domain +
        "/" +
        data.service.product,
    ]);
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.pollListForm.controls;
  }

  onSubmit() {
    this.loading = true;
    let menu_id =
      this.f.search_type.value === "menu_id" ? this.f.search_value.value : null;
    let name =
      this.f.search_type.value === "name" ? this.f.search_value.value : null;
    let status = this.f.status.value;
    let date = this.f.date.value;
    this.search(menu_id, name, status, date);
  }

  search(id: any, name: any, status: any, date: any) {
    this.currentPage = 0;
    this.pollList = {};
    this.payload = {};
    this.search_message = "";

    this.payload = {
      page_size: this.pageSize,
      page_number: 0,
      chat_id: localStorage.getItem("chat_id"),
    };

    if (id != null && id !== "") {
      this.payload["menu_id"] = id;
    }
    if (name != null && name !== "") {
      this.payload["name"] = name;
    }
    if (status != null && status !== "") {
      this.payload["status"] = status;
    }
    if (date != null && date !== "") {
      this.payload["created_date"] = date;
    }

    this.ws.processMethod(
      new ListPoll(
        0,
        localStorage.getItem("chat_id"),
        this.component_ref,
        this.payload["menu_id"],
        this.payload["status"],
        this.payload["name"],
        this.payload["created_date"]
      )
    );
  }
  resetSearch() {
    this.currentPage = 0;
    this.pollList = {};
    this.search_message = "";

    this.payload["page_size"] = this.pageSize;
    this.payload["page_number"] = this.currentPage;
    this.payload["chat_id"] = localStorage.getItem("chat_id");

    this.ws.processMethod(
      new ListPoll(
        this.currentPage,
        localStorage.getItem("chat_id"),
        this.component_ref,
        this.payload["menu_id"],
        this.payload["status"],
        this.payload["name"],
        this.payload["created_date"]
      )
    );
  }

  nextPage() {
    // console.log("request");
    this.payload["page_size"] = this.pageSize;
    this.payload["page_number"] = this.currentPage;
    this.payload["chat_id"] = localStorage.getItem("chat_id");

    this.ws.processMethod(
      new ListPoll(
        this.currentPage,
        localStorage.getItem("chat_id"),
        this.component_ref,
        this.payload["menu_id"],
        this.payload["status"],
        this.payload["name"],
        this.payload["created_date"]
      )
    );
  }

  backPage() {
    if (this.currentPage != 1) {
      this.currentPage--;
      this.page = [];
      for (let i = 0; i < this.pageSize; i++) {
        if (
          Object.keys(this.pollList)[
            i + (this.currentPage - 1) * this.pageSize
          ]
        ) {
          this.page.push(
            this.pollList[
              Object.keys(this.pollList)[
                i + (this.currentPage - 1) * this.pageSize
              ]
            ]
          );
        }
      }
      if (this.currentPage == 1) {
        document.getElementById("pagination_back").classList.add("d-none");
        document
          .getElementById("pagination_back")
          .classList.remove("enablePointer");
      }
      if (
        this.currentPage <
        Object.keys(this.pollList).length / this.pageSize
      ) {
        document.getElementById("pagination_next").classList.remove("d-none");
        document
          .getElementById("pagination_next")
          .classList.add("enablePointer");
      }
    }
  }

  abbreviateNumber(value: any) {
    var newValue = value;
    if (value >= 1000) {
      var suffixes = ["", "K", "M", "B", "T"];
      var suffixNum = Math.floor(("" + value).length / 3);
      var shortValue: any;
      for (var precision = 2; precision >= 1; precision--) {
        shortValue = parseFloat(
          (suffixNum != 0
            ? value / Math.pow(1000, suffixNum)
            : value
          ).toPrecision(precision)
        );
        var dotLessShortValue = (shortValue + "").replace(
          /[^a-zA-Z 0-9]+/g,
          ""
        );
        if (dotLessShortValue.length <= 2) {
          break;
        }
      }
      if (shortValue % 1 != 0) shortValue = shortValue.toFixed(1);
      newValue = shortValue + suffixes[suffixNum];
    }
    return newValue;
  }

  getCountryName(alpha: string) {
    return iso.find((e) => e.alpha === alpha)?.name;
  }

  getPercent(frac: number) {
    return (frac * 100).toFixed(2).replace(/[.,]00$/, "") + "%";
  }

  getLangName(code: string) {
    return lang.find((e) => e.code === code)?.native;
  }

  checkUncheckAll(ev: any) {}

  editCollection(data) {
    if (this.single) {
      if (this.selectedCollectionsList[data.id]) {
        delete this.selectedCollectionsList[data.id];
      } else {
        this.selectedCollectionsList = {};
        this.selectedCollectionsList[data.id] = data;
      }
    } else {
      if (this.selectedCollectionsList[data.id]) {
        delete this.selectedCollectionsList[data.id];
      } else {
        this.selectedCollectionsList[data.id] = data;
      }
    }
  }

  insertEvent() {
    const selected = Object.values(this.selectedCollectionsList);
    this.selectedNotifier.emit(selected);
  }

  cancelEvent() {
    this.cancelNotifier.emit(true);
  }

  isObjEmpty() {
    return Object.keys(this.selectedCollectionsList).length === 0;
  }

  isSelected(id: string) {
    return this.selectedCollectionsList[id] ? true : false;
  }
}

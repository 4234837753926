<div
  *ngIf="loading"
  id="preloader"
  class="bg-transparent d-flex justify-content-center align-items-center"
>
  <div class="spinner-chase">
    <div class="chase-dot"></div>
    <div class="chase-dot"></div>
    <div class="chase-dot"></div>
    <div class="chase-dot"></div>
    <div class="chase-dot"></div>
    <div class="chase-dot"></div>
  </div>
</div>
<div class="container-fluid">
  <div class="row">
    <div class="col-lg-12">
      <div class="card m-0 shadow-none">
        <div class="card-body">
          <form
            [formGroup]="collectionListForm"
            (ngSubmit)="onSubmit()"
            class="mb-3"
          >
            <div class="row g-2">
              <div class="col-xl-4 col-lg-8">
                <div class="input-group">
                  <select
                    class="form-select input-rounded input-focus-border"
                    style="max-width: 120px"
                    formControlName="search_type"
                  >
                    <option value="name" selected>
                      {{ "GENERALS.NAME" | translate }}
                    </option>
                    <option value="collection">
                      {{ "COLLECTIONS.COLLECTION" | translate }}
                    </option>
                  </select>
                  <input
                    type="text"
                    class="form-control input-rounded input-focus-border"
                    [placeholder]="
                      user_search_placeholder_map[
                        collectionListForm.get('search_type').value
                      ] | translate
                    "
                    formControlName="search_value"
                  />
                </div>
              </div>
              <div class="col-xl-2 col-lg-4">
                <div class="mb-3">
                  <input
                    class="form-control input-rounded input-focus-border"
                    [placeholder]="'GENERALS.SELECTDATE' | translate"
                    name="dp"
                    bsDatepicker
                    [bsConfig]="{showWeekNumbers: false}"
                    autocomplete="off"
                    formControlName="created_date"
                  />
                </div>
              </div>
              <div class="col-xl-2 offset-xl-4 col-lg-4 offset-lg-8">
                <button type="submit" class="btn btn-soft-secondary w-100">
                  <i class="bx bx-search-alt align-middle"></i>
                  {{ "GENERALS.SEARCH" | translate }}
                </button>
              </div>
            </div>
          </form>
          <!-- Nav tabs -->

          <div class="row justify-content-center" *ngIf="page && page.length > 0">
            <div class="col-xl-12">
              <div [ngStyle]="{ height: mode === 'selector' ? '60vh' : 'auto' }">
                <ngx-simplebar id="scroll" class="h-100" #scrollRef>
                  <div class="row g-4 w-100">
                    <div
                      class="col-xxl-2 col-lg-3 col-md-4 col-sm-6"
                      *ngFor="let data of page"
                      (click)="editCollection(data)"
                    >
                      <div
                        class="card p-2 mb-0 border outline-variant rounded-3 shadow-none enablePointer"
                        [ngClass]="{'border-primary': isSelected(data.id)}"
                      >
                        <i class="fas fa-check-circle position-absolute top-0 end-0 m-1 font-size-18 text-primary" *ngIf="isSelected(data.id)"></i>
                        <div class="p-2 text-truncate d-flex flex-column">
                          <span class="fw-bold">
                            {{ data.name }}
                          </span>
                          <span class="text-muted mb-0 font-size-12">
                            {{ data.id }}
                          </span>
                        </div>

                        <div style="height: 150px;" class="position-relative d-flex align-items-center justify-content-center">
                          <img
                            src="{{ data?.photo?.permanentUrl }}"
                            class="rounded"
                            style="max-width: 100%; max-height: 100%"
                            onerror="this.onerror=null; this.src='assets/images/group/group-empty.webp'"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </ngx-simplebar>
              </div>
            </div>
          </div>

          <div class="row" *ngIf="page.length === 0">
            <div class="col text-center mt-4 mb-0">
              <h6>
                {{ search_message }}
              </h6>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal-footer">
  <button class="btn btn-danger me-2" (click)="cancelEvent()">Cancel</button>
  <button
    class="btn btn-success"
    [disabled]="isObjEmpty()"
    (click)="insertEvent()"
  >
    Done
  </button>
</div>

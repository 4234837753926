import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import {
  TabNames,
  c1,
  common_components_map,
  offcanvasColors,
} from "src/app/core/services/constants";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import { Tab } from "src/app/core/services/interface";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { WebsocketService } from "src/app/core/services/websocket.service";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { BuilderService } from "src/app/sharedservices/builder.service";

@Component({
  selector: "app-tab-post-footer-component",
  templateUrl: "./tab-post-footer-component.component.html",
  styleUrls: ["./tab-post-footer-component.component.scss"],
})
export class TabPostFooterComponentComponent implements OnInit, OnDestroy {
  constructor(
    private ws: WebsocketService,
    private mdw: MiddlwareService,
    public indexDBService: IndexDBService,
    private modalService: BsModalService,
    private builderService: BuilderService
  ) {}

  @Input() tab_id: string;
  tab: Tab;
  mode: string;

  // default values
  imageLg: string;
  imageSm: string;
  image: string;
  icon: string;
  icon_ios: string;
  title: string;
  roundedBottom = true;
  offCanvasSubscription: any;
  currentTabSubscription: any;
  enablePostFooter = false;
  highColor = "#ffffff";
  offcanvasColors = offcanvasColors;
  newTab = false;

  likeEnabled = false;
  likeIcon: any;
  likedIcon: any;

  replyEnabled = false;
  replyIcon: any;

  viewEnabled = false;
  viewIcon: any;

  shareEnabled = false;
  shareIcon: any;
  modalRef?: BsModalRef;
  selectedKey: string;
  selectedIconListTitle: string;
  tabList = {};
  sortedTabList = [];
  data: any;
  off_canvas_key: string = "tab";
  isAndroid: boolean;
  component_id: string;
  field_id: string;
  ngOnInit() {
    this.field_id = "tab_post_footer";

    this.currentTabSubscription = this.mdw.currentTabContainer$.subscribe(
      (data) => {
        if (
          data &&
          (data["cat"] == "home" || data["cat"] == "side") &&
          data.off_canvas_key === "tab" &&
          data.currentTab &&
          this.tab_id &&
          data.currentTab.id == this.tab_id &&
          data["component_id"] != this.component_id
        ) {
          this.data = data;
          this.sortedTabList = this.data["sortedTabList"];
          this.tabList = this.data["tabList"];
          this.tab = data["tabList"][this.tab_id];
          this.tabFunction();
        }
      }
    );

    this.offCanvasSubscription = this.mdw.offCanvasContainer$.subscribe(
      (data) => {
        if (
          data &&
          data.off_canvas_key === "tab" &&
          data.tab.id == this.tab_id
        ) {
          this.TabIntialize(data);
          this.tabFunction();
        }
      }
    );
  }

  TabIntialize(data) {
    this.data = data.data;
    if (data.tab.id == this.tab_id) {
      this.tab = { ...data.tab };
      this.component_id = this.tab.id + this.field_id;
    }
    this.sortedTabList = this.data["sortedTabList"];
    this.tabList = this.data["tabList"];
    this.mode = localStorage.getItem("mode");
  }

  tabFunction() {
    if (this.tab) {
      this.isAndroid = localStorage.getItem("mode")[0] === c1.ANDRIOD ? true : false;
      this.enablePostFooter = this.tab.footer.hidden ? false : true;
      for (let i = 0; i < this.tab.footer.action.length; i++) {
        switch (this.tab.footer.action[i].link) {
          case "like":
            if (this.tab.footer.action[i].status) {
              this.likeEnabled = true;
            }
            this.likeIcon = this.builderService.getIcon(
              this.tab.footer.action[i].icon
            );
            break;
          case "reply":
            if (this.tab.footer.action[i].status) {
              this.replyEnabled = true;
            }

            this.replyIcon = this.builderService.getIcon(
              this.tab.footer.action[i].icon
            );
            break;
          case "view":
            if (this.tab.footer.action[i].status) {
              this.viewEnabled = true;
            }

            this.viewIcon = this.builderService.getIcon(
              this.tab.footer.action[i].icon
            );
            break;
          case "share":
            if (this.tab.footer.action[i].status) {
              this.shareEnabled = true;
            }

            this.shareIcon = this.builderService.getIcon(
              this.tab.footer.action[i].icon
            );
            break;
          case "like_highlight":
            this.highColor = this.tab.footer.action[i].fill_color;
            this.likedIcon = this.builderService.getIcon(
              this.tab.footer.action[i].icon
            );
            break;
        }
      }
    }
  }

  toggleFooter(event) {
    this.enablePostFooter = event;
    if (this.enablePostFooter) {
      this.tab.footer.hidden = 0;
    } else {
      this.tab.footer.hidden = 1;
    }

    this.updateTabData();
  }

  enableCheckbox(event, item) {
    const action = [];
    switch (item) {
      case "like":
        this.likeEnabled = event.target.checked;
        // this.tab.footer.action.forEach((item) => item.link === "like" ? { ...item, status: event.target.checked }: item    );
        // this.tab.footer.action.forEach((item) => item.link === "like_highlight" ? { ...item, status: event.target.checked }: item    );
        this.tab.footer.action.forEach((item) => {
          if (item.link === "like" || item.link === "like_highlight") {
            const newLikeItem = item;
            newLikeItem.status = event.target.checked;
            action.push(newLikeItem);
          } else {
            action.push(item);
          }
        });
        break;
      case "reply":
        this.replyEnabled = event.target.checked;
        this.tab.footer.action.forEach((item) => {
          if (item.link === "reply") {
            const newReplyItem = item;
            newReplyItem.status = event.target.checked;
            action.push(newReplyItem);
          } else {
            action.push(item);
          }
        });
        break;
      case "view":
        this.viewEnabled = event.target.checked;
        this.tab.footer.action.forEach((item) => {
          if (item.link === "view") {
            const newViewItem = item;
            newViewItem.status = event.target.checked;
            action.push(newViewItem);
          } else {
            action.push(item);
          }
        });
        break;
      case "share":
        this.shareEnabled = event.target.checked;
        this.tab.footer.action.forEach((item) => {
          if (item.link === "share") {
            const newShareItem = item;
            newShareItem.status = event.target.checked;
            action.push(newShareItem);
          } else {
            action.push(item);
          }
        });
        break;
    }
    this.tab.footer.action = action;
    this.updateTabData();
  }

  colorTextPickerChange(event) {
    const action = [];
    this.tab.footer.action.forEach((item) => {
      if (item.link === "like_highlight") {
        const newLikedItem = item;
        newLikedItem.fill_color = this.highColor;
        action.push(newLikedItem);
      } else {
        action.push(item);
      }
    });
    this.updateTabData();
  }

  changeIcon(value) {
    const action = [];
    switch (this.selectedKey) {
      case "like":
        this.likeIcon = this.builderService.getIcon(value);
        // this.tab.footer.action.forEach((item) => item.link === "like" ? { ...item, icon: value}: item);
        this.tab.footer.action.forEach((item) => {
          if (item.link === "like") {
            const newLikeItem = item;
            newLikeItem.icon = value;
            action.push(newLikeItem);
          } else {
            action.push(item);
          }
        });
        break;
      case "reply":
        this.replyIcon = this.builderService.getIcon(value);
        // this.tab.footer.action.forEach((item) => item.link === "reply" ? { ...item, icon: value}: item);
        this.tab.footer.action.forEach((item) => {
          if (item.link === "reply") {
            const newReplyItem = item;
            newReplyItem.icon = value;
            action.push(newReplyItem);
          } else {
            action.push(item);
          }
        });
        break;
      case "view":
        this.viewIcon = this.builderService.getIcon(value);
        // this.tab.footer.action.forEach((item) => item.link === "view" ? { ...item, icon: value}: item);
        this.tab.footer.action.forEach((item) => {
          if (item.link === "view") {
            const newViewItem = item;
            newViewItem.icon = value;
            action.push(newViewItem);
          } else {
            action.push(item);
          }
        });
        break;
      case "share":
        this.shareIcon = this.builderService.getIcon(value);
        // this.tab.footer.action.forEach((item) => item.link === "share" ? { ...item, icon: value}: item);
        this.tab.footer.action.forEach((item) => {
          if (item.link === "share") {
            const newShareItem = item;
            newShareItem.icon = value;
            action.push(newShareItem);
          } else {
            action.push(item);
          }
        });
        break;
      case "like_highlight":
        this.likedIcon = this.builderService.getIcon(value);
        // this.tab.footer.action.forEach((item) => item.link === "like_highligted" ? { ...item, icon: value}: item);
        this.tab.footer.action.forEach((item) => {
          if (item.link === "like_highlight") {
            const newLikedItem = item;
            newLikedItem.icon = value;
            action.push(newLikedItem);
          } else {
            action.push(item);
          }
        });
        break;
    }

    this.tab.footer.action = action;
    this.selectedKey = undefined;
    this.selectedIconListTitle = undefined;
    this.updateTabData();
    this.modalRef.hide();
  }

  openSelector(exlargeModal: any, key) {
    this.selectedKey = key;
    switch (this.selectedKey) {
      case "like":
        this.selectedIconListTitle = "Post Like";
        break;
      case "reply":
        this.selectedIconListTitle = "Post Reply";
        break;
      case "view":
        this.selectedIconListTitle = "Post View";
        break;
      case "share":
        this.selectedIconListTitle = "Post Share";
        break;
      case "like_highlight":
        this.selectedIconListTitle = "Post Liked";
        break;
    }
    this.modalRef = this.modalService.show(exlargeModal, { class: "modal-xl" });
  }

  updateTabData() {
    //*must be there in all Tabs
    this.tabList[this.tab_id] = this.tab;

    let index = this.sortedTabList.findIndex((e) => e.id == this.tab_id);
    this.sortedTabList[index] = { ...this.tab };
    this.data["sortedTabList"] = this.sortedTabList;
    this.data["TabList"] = this.tabList;
    this.data["off_canvas_key"] = this.off_canvas_key;
    this.data["current_tab"] = this.tab;

    this.mdw._currentTabContainer.next(this.data);
    this.mdw.changeTab({ tab: this.tab }, this.mode, false);
  }

  ngOnDestroy(): void {
    if (this.offCanvasSubscription) {
      this.offCanvasSubscription.unsubscribe();
    }
    if (this.currentTabSubscription) {
      this.currentTabSubscription.unsubscribe();
    }
  }
}

import { LightboxModule } from 'ngx-lightbox';
import { BuilderService } from '../../sharedservices/builder.service';
import { Footer, Tab } from "../../core/services/interface";
import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { TabNames, c1, common_components_map } from "src/app/core/services/constants";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { WebsocketService } from "src/app/core/services/websocket.service";

@Component({
  selector: "app-palette",
  templateUrl: "./palette.component.html",
  styleUrls: ["./palette.component.scss"],
})
export class PaletteComponent implements OnInit, OnDestroy {
  // bread crumb items
  updateBuilderComponentSubscribtion: any;
  getAppConfigSubscription: any;

  appHome: any = {id: "home", app:{tabs:{tabs:[]}} };
  tab: Tab;
  deviceColors: any = {};
  pallete : any ={};
  isAndroid: boolean;

  @Input() inputTab: Tab;
  @Input() fontResize = false;
  @Input() fontSmaller = false;

  constructor(
    private mdw: MiddlwareService,
    private ws: WebsocketService,
    private indexDBService: IndexDBService,
    public BuilderService: BuilderService
  ) { }

  ngOnInit() {

    this.getAppConfigSubscription = this.mdw.getAppConfigContainer$.subscribe((data) => {
      if (data) {
        this.initialize()
      }
    });

    // try {
    //  this.initialize()
    // } catch (error) {
    //   if (localStorage.getItem("ndbx")) {
    //     setTimeout(async () => {
    //       this.initialize()
    //     }, 1500);
    //   } else {
    //     setTimeout(async () => {
    //       this.initialize()
    //     }, 2000);
    //   }
    // }


    this.updateBuilderComponentSubscribtion =
      this.mdw.updateBuilderComponentContainer$.subscribe((data) => {

        if (data && common_components_map[data.tab.module].type == TabNames.SPLASH) {
          if (this.inputTab) {
            this.tab = { ...this.inputTab };
          } else {
            this.tab = { ...data.tab };
          }
          try {
            this.initialize()
           } catch (error) {
             if (localStorage.getItem("ndbx")) {
               setTimeout(async () => {
                 this.initialize()
               }, 1500);
             } else {
               setTimeout(async () => {
                 this.initialize()
               }, 2000);
             }
           }

            };
         })
    }



  initialize() {
    this.isAndroid = localStorage.getItem("mode")[0]==c1.ANDRIOD? true: false

    const appHome = this.indexDBService.getItem("app", "home");
    appHome.onsuccess = (event) => {
      if (appHome.result) {
        this.appHome = appHome.result;
        if (this.appHome.app && this.appHome.app.tabs && this.appHome.app.tabs.color_schema) {
          this.deviceColors.color_schema = this.appHome.app.tabs.color_schema;
        }
        // ios
        if (this.appHome.app && this.appHome.app.tabs && this.appHome.app.tabs.color_schema_ios) {
          this.deviceColors.color_schema_ios = this.appHome.app.tabs.color_schema_ios;
        }
        this.pallete = this.isAndroid? this.deviceColors.color_schema? this.deviceColors.color_schema.schemes:null:this.deviceColors.color_schema_ios? this.deviceColors.color_schema_ios.schemes:null;
      }

    }
  }

  ngOnDestroy(): void {
    if (this.updateBuilderComponentSubscribtion) {
      this.updateBuilderComponentSubscribtion.unsubscribe();
    }
    if (this.getAppConfigSubscription) {
      this.getAppConfigSubscription.unsubscribe();
    }
  }
}

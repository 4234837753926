<!-- ========== Left Sidebar Start ========== -->

<ng-template #contentTemplate>
  <!--- Sidemenu -->
  <div id="sidebar-menu" class="sidebar-menu p-0" (mouseenter)="hoverSideMenu($event)" (mouseleave)="hoverSideMenu($event)">
    <!-- Left Menu Start -->
    <ul class="metismenu list-unstyled" id="side-menu" #sideMenu>
      <!-- {{menuItems}} -->
      <ng-container *ngFor="let item of menuItems; index as index">
        <li class="menu-title pb-2" *ngIf="item.isTitle">
          <hr *ngIf="index != 0" class="border-top outline mt-0 mb-4"/>
          <span class="text-truncate font-size-12">{{ item.label | translate }}</span>
        </li>
        <li *ngIf="!item.isTitle && !item.isLayout" class="overflow-x-hidden">
          <a
            *ngIf="hasItems(item)"
            class="is-parent offcanvas-parent py-2 d-flex align-items-center justify-content-between enablePointer"
            [ngClass]="(isCondensed && !isHover) ? 'px-2' : 'px-3'"
          >
            <div class="d-flex align-items-center">
              <i class="offcanvas-icon rounded-3 py-2 px-2 font-size-20 material-icons-outlined" [ngStyle]="{'color': item?.icon_color}">{{item?.icon}}</i>
              <span class="text-truncate font-size-13 ms-3">{{ item.label | translate }}</span>
            </div>
            <div *ngIf="!isCondensed || isHover" class="dropdown-icon">
              <i class="font-size-22 mdi mdi-chevron-down" style="min-width: 0;"></i>
            </div>
          </a>
          <a
            routerLink="{{ item.link }}"
            *ngIf="!hasItems(item)"
            routerLinkActive="active"
            class="side-nav-link-ref offcanvas-parent py-2 d-flex align-items-center justify-content-start enablePointer"
            [ngClass]="(isCondensed && !isHover) ? 'px-2' : 'px-3'"
          >
            <i class="offcanvas-icon rounded-3 py-2 px-2 font-size-20 material-icons-outlined" [ngStyle]="{'color': item?.icon_color}">{{item?.icon}}</i>
            <span class="text-truncate font-size-13 ms-3">{{ item.label | translate }}</span>
          </a>

          <ul *ngIf="hasItems(item)" class="sub-menu" aria-expanded="false">
            <li *ngFor="let subitem of item.subItems">
              <a
                routerLink="{{ subitem.link }}"
                *ngIf="!hasItems(subitem)"
                class="side-nav-link-ref"
                [attr.data-parent]="subitem.parentId"
                routerLinkActive="active"
              >
                <span class="font-size-12 ps-2 ms-1">{{ subitem.label | translate }}</span>
                <span
                  class="badge rounded-pill bg-{{
                    subitem.badge.variant
                  }} float-end"
                  *ngIf="subitem.badge"
                  >{{ subitem.badge.text | translate }}</span
                >
              </a>
              <a
                *ngIf="hasItems(subitem)"
                class="side-nav-link-a-ref has-arrow"
                href="javascript:void(0);"
                [attr.data-parent]="subitem.parentId"
              >
                {{ subitem.label | translate }}
              </a>
              <ul
                *ngIf="hasItems(subitem)"
                class="sub-menu mm-collapse"
                aria-expanded="false"
              >
                <li *ngFor="let subSubitem of subitem.subItems">
                  <a
                    [attr.data-parent]="subSubitem.parentId"
                    routerLink="{{ subSubitem.link }}"
                    routerLinkActive="active"
                    class="side-nav-link-ref"
                  >
                    {{ subSubitem.label | translate }}
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ng-container>
    </ul>
  </div>
</ng-template>
<!-- Sidebar -->

<div class="vertical-menu overflow-y-auto invisible-scrollbar">
  <!-- <ngx-simplebar class="h-100" #componentRef> -->
    <!-- <div *ngIf="!isCondensed"> -->
      <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    <!-- </div> -->
    <!-- <div *ngIf="isCondensed">
      <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </div> -->
  <!-- </ngx-simplebar> -->
</div>
<!-- Left Sidebar End -->
